import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sizeDisplay'
})
export class SizeDisplayPipe implements PipeTransform {
  transform(value: string): any {
    let result = null;
    let intValue = Number(value);

    if (!isNaN(intValue)) {
      if (intValue / 1024 / 1024 < 1) {
        result = (intValue / 1024).toFixed(2) + " MB"
      } else
        result = (intValue / 1024 / 1024).toFixed(2) + " GB"
    }

    return result;
  }
}
