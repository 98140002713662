import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Public_ROUTES: Routes = [
  {
    path: 'tx',
    loadChildren: () => import('../../public/public.module').then(m => m.PublicModule)
  },
];
