import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'receiptCompFilter'
})
export class ReceiptCompPipe implements PipeTransform {
  transform(value: string): any {
    let compName = null;

    if (value) {
      let oriLines = value.split('\n');
      let lines;

      if (oriLines) {
        lines = oriLines.filter(x => x.toLowerCase().indexOf(" s/b") >= 0 || x.toLowerCase().indexOf("sdn bhd") >= 0 || x.toLowerCase().indexOf("sdn. bhd") >= 0 ||
          x.toLowerCase().indexOf(" trading") >= 0 || x.toLowerCase().indexOf(" enterprise") >= 0 ||
          x.toLowerCase().indexOf("restaurant") >= 0 || x.toLowerCase().indexOf("restoran") >= 0 || x.toLowerCase().indexOf("restor") >= 0 || 
          x.toLowerCase().indexOf("berhad") >= 0 || x.toLowerCase().indexOf("corporation") >= 0 || x.toLowerCase().indexOf("shopping") >= 0);

        if (lines && lines.length > 0) {
          compName = lines.join(',\n');
        }
        else {
          lines = oriLines.filter(x => x.toLowerCase().indexOf("petronas") >= 0 || x.toLowerCase().indexOf("shell") >= 0 ||
            x.toLowerCase().indexOf("caltex") >= 0 || x.toLowerCase().indexOf("petron") >= 0 || x.toLowerCase().indexOf("bakery") >= 0 ||
            x.toLowerCase().indexOf("dewan bandaraya kuala lumpur") >= 0 || x.toLowerCase().indexOf("klinik") >= 0 || x.toLowerCase().indexOf("clinic") >= 0 ||
            x.toLowerCase().indexOf("kfc") >= 0 || x.toLowerCase().indexOf("sst") >= 0 || x.toLowerCase().indexOf(" mall") >= 0 ||
            x.toLowerCase().indexOf("center") >= 0 || x.toLowerCase().indexOf("centre") >= 0);

          if (lines && lines.length > 0) {
            compName = lines.join(',\n');
          } else {
            lines = oriLines.filter(x => x.toLowerCase().indexOf("invoice id") >= 0 || x.toLowerCase().indexOf("sales id") >= 0 ||
              x.toLowerCase().indexOf(" id") >= 0);

            if (lines && lines.length > 0) {
              compName = lines.join(',\n');
            }
          }
        }
      }
    }

    return compName;
  }
}
