import { Component, Output, EventEmitter, Input, ViewChild, NgZone, forwardRef, ChangeDetectorRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import Swal from 'sweetalert2';
import { CommonFunction } from '../common/common_function';
import { LanguageData, LocalStorageType } from '../model/system';

@Component({
  selector: 'app-gallery-uploader',
  templateUrl: './gallery-uploader.component.html',
  styleUrls: ['./gallery-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GalleryUploaderComponent),
      multi: true
    }
  ]
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class GalleryUploaderComponent implements ControlValueAccessor {

  croppedImage: any = '';
  data: any;
  isImageUpdate = false;
  hasImage = false;
  filePickerData: any;
  filePickerURL: string;

  isLoadingImage = false;

  imageSequence: number;
  imageTitle: string;
  imageDescription: string;

  imageCropped(event: ImageCroppedEvent) {
    this.data = event.base64
  }

  resetImageCropper() {
    this.currentImage = null;
    this.data = null;
    this.filePickerURL = null;
    this.filePickerData = null;
    this.hasImage = false;
    this.isImageUpdate = false;
    this.imageSequence = this.val.length + 1;
    this.imageTitle = null;
    this.imageDescription = null;
  }


  currentImage: ImageModel;
  // @Input() imageList: ImageModel[] = [];
  @Input() aspectRatio: number;
  @Input() resizeToWidth: number;
  @Input() resizeToHeight: number;
  @Input() label: string;
  @Input() canEdit: boolean;
  @Input() isCirclePreview: boolean = true;
  @Input() maxImageCount: number = 20;
  @Input() enableImageTitle: boolean = true;
  @Input() enableImageDescription: boolean = true;
  @Input() isFullscreenThumbnail: boolean = false;
  @Input() thumbnailClass: string = 'col-4 col-md-3 col-lg-2 mb-1';
  @Input() skipCrop: boolean = false; // skip crop, using default

  disabled: boolean;

  constructor(private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,) {
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  val: ImageModel[] = [] // this is the updated value that the class accesses
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val !== undefined && this.val !== val) {
      this.val = val

      this.onChange(val)
      this.onTouch(val)
    }
  }

  // this method sets the value programmatically
  writeValue(value: any): void {
    this.value = value
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  /**    
  * This function is called when the control status changes to or from "DISABLED".
  * Depending on the value, it will enable or disable the appropriate DOM element.
  * @param isDisabled
  */
  setDisabledState?(isDisabled: boolean): void {
  }

  fileChangeListener($event) {
    this.resetImageCropper();

    this.isLoadingImage = true;

    var image: any = new Image();
    var file: File = $event.target.files[0];

    this.isImageUpdate = true;

    var myReader: FileReader = new FileReader();
    var that = this;
    myReader.onloadend = function (loadEvent: any) {

      image.src = loadEvent.target.result;

      if (that.skipCrop) {
        CommonFunction.compressImage(image.src).then(compressed => {
          that.data = compressed;

          that.addImageToList();
        });
      } else {
        that.filePickerData = image.src;
      }

      if (JSON.stringify(image.src) != '{}') {
        that.hasImage = true;
      }

      that.cdr.markForCheck();
    };

    myReader.readAsDataURL(file);
  }

  loadImageFailed() {
    this.isLoadingImage = false;
  }

  imageLoaded() {
    this.isLoadingImage = false;
  }

  deleteImage() {
    let index = this.val.findIndex(x => x.ImageID == this.currentImage.ImageID);

    this.val.splice(index, 1);

    this.resetImageCropper();
  }

  addImageToList() {
    if (this.imageSequence <= 0) {
      this.imageSequence = 1;
    } else if (this.imageSequence > this.val.length + 1) {
      this.imageSequence = this.val.length + 1;
    }

    if (this.currentImage) {
      let foundImage = this.val.find(x => x.ImageID == this.currentImage.ImageID);
      let foundImageIndex = this.val.findIndex(x => x.ImageID == this.currentImage.ImageID);

      foundImage.ImageData = this.data
      foundImage.Sequence = this.imageSequence;
      foundImage.Title = this.imageTitle;
      foundImage.Description = this.imageDescription;

      // Check sequence if need to shuffle the sequence
      if ((foundImageIndex + 1) != foundImage.Sequence) {
        this.val.splice(foundImageIndex, 1);

        // Put image at desired location
        this.val.splice(this.imageSequence - 1, 0, foundImage);
      }

      // Update image sequence
      this.updateImagesSequence();

    } else {
      let newImage = new ImageModel({
        ImageData: this.data,
        Title: this.imageTitle,
        Description: this.imageDescription,
        Sequence: this.imageSequence
      })

      if (this.imageSequence != this.val.length + 1) {
        // Put image at desired location
        this.val.splice(this.imageSequence - 1, 0, newImage);

        // Update image sequence
        this.updateImagesSequence();
      } else {
        this.val.push(newImage);
      }

    }

    this.onChange(this.val);

    // Clear out the preview data
    this.resetImageCropper();
  }

  updateImagesSequence() {
    let seq = 1;

    this.val.forEach(x => {
      x.Sequence = seq;
      seq++;
    });
  }

  async confirmDelete(img: ImageModel) {

    try {
      await Swal.fire({
        icon: 'warning',
        title: this.translate.instant('Confirm to delete?'),
        showCancelButton: true,
        confirmButtonText: this.translate.instant('Yes'),
        cancelButtonText: this.translate.instant('No'),
        allowOutsideClick: false
      }).then(async result => {
        if (result.value) {
          this.currentImage = new ImageModel({
            ImageID: img.ImageID
          })

          this.deleteImage();
        }
      })
    } catch (e) {
      Swal.fire({
        icon: 'error',
        title: this.translate.instant('Error'),
        html: this.translate.instant(e),
      })
    }
  }

  async editImage(img: ImageModel) {

    if (this.skipCrop) {
      if (!img.ImageData && !img.URL) {
        return;
      }

      if (img.URL) {
        window.open(img.URL, '_blank');
      } else {

        const dataUrlArr = img.ImageData.toString().split(';');

        if (dataUrlArr.length == 2) {
          const dataType = dataUrlArr[0].split(':')[1];
          const dataContent = dataUrlArr[1].split(',')[1];

          var byteString = atob(dataContent);
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);

          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }

          const pdfUrl = URL.createObjectURL(
            new Blob([ab], { type: dataType }),
          );
          window.open(pdfUrl, '_blank');
        }
      }
      // window.open("data:application/pdf," + encodeURI(img.ImageData));
      return;
    }

    this.isImageUpdate = true;
    this.currentImage = img;
    this.imageSequence = img.Sequence;
    this.imageTitle = img.Title;
    this.imageDescription = img.Description;

    if (img.ImageData) {
      this.filePickerData = img.ImageData;
      // this.filePickerURL = null;
    } else {
      const result: any = await fetch(img.URL);
      const blob = await result.blob();
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        this.filePickerData = reader.result;

        // this.filePickerData = null;
        // this.filePickerURL = img.URL;

        this.cdr.markForCheck();
      }
    }
  }

  // uploadSelection() {
  //   let isMobile = this.deviceService.isMobile();
  //   let isTablet = this.deviceService.isTablet();

  //   // alert('isMobile: ' + isMobile + ', isTablet: ' + isTablet)

  //   let element = document.getElementById('custom-input') as HTMLElement;

  //   if (!isMobile && !isTablet) {
  //     element.click();
  //   } else {
  //     Swal.fire({
  //       title: 'Select from?',
  //       showCancelButton: true,
  //       confirmButtonColor: '#f88626',
  //       confirmButtonText: `<i class="fa fa-camera"></i> Camera`,
  //       cancelButtonColor: '#a169ff',
  //       cancelButtonText: `<i class="fa ft-folder"></i> Gallery`,
  //     }).then((result) => {
  //       /* Read more about isConfirmed, isDenied below */
  //       if (result.dismiss == Swal.DismissReason.backdrop) {
  //       }
  //       else if (result.dismiss == Swal.DismissReason.cancel) {
  //         element.click();
  //         // Swal.fire('Changes are not saved', '', 'info')
  //       } else {
  //         this.router.navigate(['/pages/camera'], {
  //           queryParams: {
  //             Hook: this.router.url
  //           },
  //           queryParamsHandling: 'merge',
  //           relativeTo: this.activatedRoute
  //         });

  //         // Swal.fire('Saved!', '', 'success')
  //       }
  //     })
  //   }
  // }
}

export class ImageModel {
  ImageID: string = CommonFunction.GenGUID();
  URL: string;
  ImageData: any;
  Sequence: number;
  Title: string;
  Description: string;

  public constructor(init?: Partial<ImageModel>) {
    Object.assign(this, init);
  }
}