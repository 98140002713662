import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Device_ROUTES: Routes = [
  {
    path: 'setting',
    loadChildren: () => import('../../setting/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'device',
    loadChildren: () => import('../../device/device.module').then(m => m.DeviceModule)
  },
];
