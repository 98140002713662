import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";

import { FilterPipe } from './filter.pipe';
import { SearchPipe } from './search.pipe';
import { ShortNamePipe } from './short-name.pipe';
import { MyFilterPipe } from './myfilter.pipe';
import { ReceiptCompPipe } from './receipt-comp-name.pipe';
import { ReceiptAddressPipe } from './receipt-address.pipe';
import { ReceiptContactPipe } from './receipt-contact.pipe';
import { ReceiptCompNoPipe } from './receipt-comp-reg.pipe';
import { SizeDisplayPipe } from './size-display.pipe';
import { TableFilterPipe } from './table-filter.pipe';
import { LevelFilterPipe } from './levelfilter.pipe';
import { KeyDataSelectorPipe } from './key-data-selector.pipe';
import { SafeUrlPipe } from './safeUrl.pipe';
import { FileSizePipe } from './file-size.pipe';
import { OrderStatusPipe } from './order-status.pipe';

@NgModule({
  declarations: [FilterPipe, SearchPipe, ShortNamePipe, MyFilterPipe, ReceiptCompPipe, ReceiptAddressPipe, 
    ReceiptContactPipe, ReceiptCompNoPipe, SizeDisplayPipe, TableFilterPipe, LevelFilterPipe, KeyDataSelectorPipe, 
    SafeUrlPipe, FileSizePipe, OrderStatusPipe],
  imports: [CommonModule],
  exports: [FilterPipe, SearchPipe, ShortNamePipe, MyFilterPipe, ReceiptCompPipe, ReceiptAddressPipe, 
    ReceiptContactPipe, ReceiptCompNoPipe, SizeDisplayPipe, TableFilterPipe, LevelFilterPipe, KeyDataSelectorPipe, 
    SafeUrlPipe, FileSizePipe, OrderStatusPipe]
})

export class PipeModule { }
