import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Billing_ROUTES: Routes = [
  {
    path: 'billing',
    loadChildren: () => import('../../billing/billing.module').then(m => m.BillingModule)
  },
  {
    path: 'dynamic',
    loadChildren: () => import('../../dynamic-page/dynamic-page.module').then(m => m.DynamicPageModule)
  },
  // {
  //   path: 'company',
  //   loadChildren: () => import('../../company/company.module').then(m => m.CompanyModule)
  // },
];
