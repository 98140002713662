import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../auth/auth.service';

@Pipe({
  name: 'levelfilter'
})
export class LevelFilterPipe implements PipeTransform {
  constructor(private authService: AuthService) {

  }

  transform(items: any[]): any {
    if (!items) {
      return null;
    }

    switch (this.authService.getEmployee().LevelName) {
      case 'Management':
        return items.filter(x => x['title'].indexOf('Admin') < 0 && x['title'].indexOf('MR'));
        // return items.filter(
        //   item =>
        //     !item['title']
        //       .toLowerCase()
        //       .includes('Admin'.toLowerCase() &&
        //     !item['title']
        //       .toLowerCase()
        //       .includes('MR'.toLowerCase()) === true
        // )
        break;
      case 'MR':
        return items.filter(x => x['title'].indexOf('Admin') < 0);
        return items.filter(
          item =>
            !item['title']
              .toLowerCase()
              .includes('Admin'.toLowerCase()) === true
        )
        break;
      default:
        return items.filter(x => x['title'].indexOf('Admin') < 0 && x['title'].indexOf('MR'));
        break;
    }

    return items;

    // return items.filter(
    //   item =>
    //     item[labelKey || 'CompanyName']
    //       .toLowerCase()
    //       .includes(searchTerm.toLowerCase()) === true
    // ).slice(0,10);
  }
}
