<div class="form-group">
    <label for="CoverPhoto">{{label}} ({{'Up to ' + maxImageCount + ' images'}})</label>
    <div class="row">
        <div [class]="thumbnailClass + ' mb-1'" *ngIf="canEdit && val?.length < maxImageCount">
          <img class="fullSizeImage preview-rounded-circle select-photo" (click)="file.click();" src="assets/img/add-image.png">
        </div>
        <div [class]="(isFullscreenThumbnail ? 'col-8 col-md-9 col-lg-12 mb-11' : thumbnailClass) + ' mb-1'" *ngFor="let img of val">
          <img [class]="isCirclePreview ? 'fullSizeImage preview-rounded-circle select-photo' : 'fullSizeImage select-photo'" (click)="editImage(img)" [src]="img.ImageData ? img.ImageData : img.URL">
          <button *ngIf="skipCrop && canEdit" class="btn btn-danger col-12" (click)="confirmDelete(img)">Delete</button>
        </div>
    </div>
</div>

<input [hidden]="true" [disabled]="disabled" #file type="file" class="custom-file-input"
    (change)="fileChangeListener($event)" accept="image/*" style="display:none">

<!-- Hide preview and cropper when in VIEW mode-->
<div class="row" *ngIf="canEdit">
    <div class="col-12 col-lg-6" *ngIf="isImageUpdate">
        <label>{{'Original' | translate}}</label>
        <p class="text-center"><i class="ft-loader spinner font-large-3" *ngIf="isLoadingImage"></i></p>
        <image-cropper *ngIf="filePickerData" [hidden]="isLoadingImage" #cropper id="cropper" [disabled]="!canEdit" [imageBase64]="filePickerData"
             [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
            [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight" format="jpeg"
            (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()" (imageLoaded)="imageLoaded()">
        </image-cropper>
        <image-cropper *ngIf="filePickerURL" [hidden]="isLoadingImage" #cropper id="cropper" [disabled]="!canEdit"
            [imageURL]="filePickerURL" [maintainAspectRatio]="true" [aspectRatio]="aspectRatio"
            [resizeToWidth]="resizeToWidth" [resizeToHeight]="resizeToHeight" format="jpeg"
            (imageCropped)="imageCropped($event)" (loadImageFailed)="loadImageFailed()" (imageLoaded)="imageLoaded()">
        </image-cropper>
    </div>
    <div class="col-12 col-lg-6" [hidden]="!(isImageUpdate && !isLoadingImage)">
        <span class="result">
            <label *ngIf="canEdit">{{'Preview' | translate}}</label>
            <img [class]="isCirclePreview ? 'fullSizeImage preview-rounded-circle mb-1' : 'fullSizeImage mb-1'" [src]="data"
                onError="this.src='assets/img/no-image.jpg'" />
        </span>
        <ng-container *ngIf="canEdit">
            <div class="form-group" *ngIf="maxImageCount > 1">
              <label for="ImageSequence">Sequence</label>
              <input type="number" min="1" [disabled]="!canEdit" [(ngModel)]="imageSequence" class="form-control">
            </div>
            <div class="form-group" *ngIf="enableImageTitle">
              <label for="ImageTitle">Title</label>
              <input type="text" [disabled]="!canEdit" [(ngModel)]="imageTitle" class="form-control">
            </div>
            <div class="form-group" *ngIf="enableImageDescription">
              <label for="ImageDescription">Description</label>
              <input type="text" [disabled]="!canEdit" [(ngModel)]="imageDescription" class="form-control">
            </div>
            <!-- <button *ngIf="currentImage?.AttachmentPath" type="button" class="btn btn-raised btn-warning mr-1"
                (click)="resetImage()">
                <i class="ft-rotate-ccw"></i> RESET
            </button> -->
            <div class="form-group mb-2">
                <button type="button" class="btn btn-raised btn-danger mr-1" (click)="deleteImage()">
                    <i class="ft-rotate-ccw"></i> DELETE
                </button>
                <button type="button" class="btn btn-raised btn-primary" (click)="addImageToList()">
                    <i class="fa fa-check-square-o"></i> OK
                </button>
            </div>
        </ng-container>
    </div>
</div>
<!-- Hide preview and cropper when in VIEW mode-->
<div class="row" *ngIf="!canEdit">
    <div class="col-12 col-lg-6" *ngIf="isImageUpdate">
        <img class="fullSizeImage mb-1" [src]="filePickerData" onError="this.src='assets/img/no-image.jpg'" />
    </div>
</div>