<div class="card">
  <div class="card-content">
    <div class="row">
      <div class="col-12">
        <h1 class="card-title font-large-1 ml-2">Total:<span class="float-right mr-2">{{totalAmount | currency:'MYR '}}</span></h1>
      </div>
      <div class="col-12">
        <h1 class="card-title font-large-1 ml-2">Tender:<span class="float-right mr-2">{{tenderAmount / 100 | currency:'MYR '}}</span></h1>
      </div>
      <div class="col-12">
        <h1 class="card-title font-large-1 ml-2">Balance:<span [class]="balanceAmount < 0 ? 'float-right danger mr-2' : 'float-right success mr-2'">{{balanceAmount | currency:'MYR '}}</span></h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <app-num-pad class="col-12" (onComplete)="complete($event)" [(ngModel)]="tenderAmount" (ngModelChange)="getBalance()" [enableCheckout]="balanceAmount >= 0"></app-num-pad>
      </div>
    </div>
  </div>
</div>