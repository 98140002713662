<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}" [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown>
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
              href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <img class="langimg selected-flag" [src]="selectedLanguageFlag" alt="flag" /><span
                class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')">
                <img class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" />
                <span class="font-small-3">English</span>
              </a>
              <a class="dropdown-item" href="javascript:;" data-language="cn" (click)="ChangeLanguage('cn')">
                <img class="langimg mr-2" src="./assets/img/flags/cn.png" alt="flag" />
                <span class="font-small-3">中文</span>
              </a>
              <a class="dropdown-item" href="javascript:;" data-language="bm" (click)="ChangeLanguage('bm')">
                <img class="langimg mr-2" src="./assets/img/flags/my.png" alt="flag" />
                <span class="font-small-3">Malay</span>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>





</nav>