import { Component, EventEmitter, Input, Output } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-action-bar',
  templateUrl: './action-bar.component.html',
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class ActionBarComponent {

  @Input() showSave = false;
  @Input() showSaveWithComment = false;
  @Input() showDelete = false;
  @Input() showClose = false;
  @Input() showPrint = false;
  @Input() showDuplicate = false;
  @Input() showSign = false;
  @Input() isSubmitting = false;
  @Input() showPrintDotMatrix = false;

  @Output() onSave: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  @Output() onPrint: EventEmitter<any> = new EventEmitter();
  @Output() onDuplicate: EventEmitter<any> = new EventEmitter();
  @Output() onSign: EventEmitter<any> = new EventEmitter();
  @Output() onPrintDotMatrix: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  onSaveButton() {
    Swal.fire({
      title: 'Saving',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    })

    this.onSave.emit();
  }

  async onSaveWithCommentButton() {
    const { value: comments } = await Swal.fire({
      title: 'Any comment for this version',
      input: 'text',
      showCancelButton: false,
    })

    Swal.fire({
      title: 'Saving',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    })

    this.onSave.emit(comments);
  }

  onDeleteButton() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.value) {
        Swal.fire({
          title: 'Deleting',
          html: 'Please wait...',
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            Swal.showLoading()
          }
        })

        this.onDelete.emit();
      }
    })
  }

  onCloseButton() {
    this.onClose.emit();
  }

  onPrintButton() {
    Swal.fire({
      title: 'Generating',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    })

    this.onPrint.emit();
  }

  onDuplicateButton() {
    Swal.fire({
      title: 'Duplicating',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    })

    this.onDuplicate.emit();
  }

  async onSignButton() {
    const { value: pin } = await Swal.fire({
      title: 'Key in your PIN',
      input: 'text',
      showCancelButton: true,
    })

    if (!pin) {
      Swal.fire({
        title: 'Signing',
        html: 'Please wait...',
        allowEscapeKey: false,
        allowOutsideClick: false,
        onOpen: () => {
          Swal.showLoading();
        }
      })

      this.onSign.emit(pin);
    }
  }

  onPrintDotMatrixButton() {
    Swal.fire({
      title: 'Generating',
      html: 'Please wait...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        Swal.showLoading();
      }
    })

    this.onPrintDotMatrix.emit();
  }

}
