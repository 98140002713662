import { Routes, RouterModule } from '@angular/router';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...

export const CONTENT_NAV_ROUTES: Routes = [
  {
    path: 'landing',
    loadChildren: () => import('../../landing/landing.module').then(m => m.LandingModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('../../payment/payment.module').then(m => m.PaymentModule)
  },
];
