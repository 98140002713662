import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {
  transform(status: number): any {
    switch (status) {
      case 0:
        return 'New';
      case 1:
        return 'In Process';  // For Delivery Status -> In Process without Return
      case 2:
        return 'Completed';
      case 3:
        return 'In Process';  // For Delivery Status -> In Process with Return
      case 4:
        return 'In Process';  // For Delivery Status -> Return BUT not In Process
    }
  }
}
