import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-cashier-num-pad',
  templateUrl: './cashier-num-pad.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CashierNumPadComponent),
      multi: true
    }
  ]
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class CashierNumPadComponent implements ControlValueAccessor {

  constructor(private cdr: ChangeDetectorRef) {
    
  }

  onChange: any = () => { }
  onTouch: any = () => { }


  @Input() totalAmount = 0;
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  tenderAmount = 0;
  balanceAmount = 0;

  private _val = 0; // this is the updated value that the class accesses

  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val && !isNaN(val)) {
      this._val = val
    } else {
      this._val = 0;
    }

    this.onChange(val)
    this.onTouch(val)
  }

  get value() {
    return this._val;
  }

  // this method sets the value programmatically
  writeValue(value: any): void {
    console.log(this.value)
    console.log(value)
    if (this.value == 0) {
      this.value = value;
    } else {
      this.value = this.value * 10 + value
    }
  }

  // this method sets the value programmatically
  setFixValue(value: any): void {
    this.value = value
  }

  clearOneDigit() {
    this.value = Math.floor(this.value / 10)
  }

  clear() {
    this.value = 0;
  }

  complete(data) {
    console.log('cashier tender ' + data)

    this.onComplete.emit({ tender: this.tenderAmount / 100, balance: this.balanceAmount });
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  /**    
  * This function is called when the control status changes to or from "DISABLED".
  * Depending on the value, it will enable or disable the appropriate DOM element.
  * @param isDisabled
  */
  setDisabledState?(isDisabled: boolean): void {
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit() {
    this.getBalance();
  }

  getBalance() {
    this.balanceAmount = (this.tenderAmount / 100) - this.totalAmount

    this.cdr.detectChanges();
  }
}
