import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
// import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { LayoutService } from './layout.service';
import { AuthService } from '../auth/auth.service';
// import { ServiceResponse } from '../model/serviceResponse';
import { map, filter, catchError } from 'rxjs/operators';
import { LocalStorageType } from '../model/system';
import Swal from 'sweetalert2';
import { CommonFunction } from '../common/common_function';

@Injectable()
export class GlobalHttpInterceptorService implements HttpInterceptor {
    constructor(private router: Router,
        private authService: AuthService,
        private layoutService: LayoutService,) {
    }

    //https://ultimatecourses.com/blog/intro-to-angular-http-interceptors
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        const token: string = localStorage.getItem(LocalStorageType.Session);

        // Override all token
        if (token) {
            // let Authorization = req.headers.get('Authorization');

            // if (Authorization) {
            // } else {
            req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });
            // }
        }

        // const reqCopy = req.clone()

        // if (reqCopy instanceof HttpResponse) {
        //     if (reqCopy.body) {
        //     }
        // }

        return next.handle(req)
            .pipe(
                filter(event => event instanceof HttpResponse),
                map(
                    (event: HttpResponse<any>) => {

                        // Only process if data match this pattern
                        if (event.body) {

                            let dataStr = JSON.stringify(event.body);

                            if (dataStr.indexOf("Result") >= 0 && dataStr.indexOf("ResponseResult") >= 0 &&
                                dataStr.indexOf("ErrorCode") >= 0 && dataStr.indexOf("Message") >= 0) {

                                if (!event.body.Result) {
                                    if (event.body.Message) {
                                        throw new HttpErrorResponse({
                                            error: event.body.Message ? event.body.Message.replace("[Msg]", "") : "",
                                            headers: event.headers,
                                            status: 500,
                                            statusText: 'Warning',
                                            url: event.url
                                        });
                                    }
                                }
                                // Previously was checking using event.body.ResponseResult to see if has data.
                                // Now should rely on when Result has ANY VALUE, taht is a ResponseResult data
                                else if (event.body.Result) {
                                    return event.clone({ body: event.body.ResponseResult })
                                }

                            } else {
                                // MUST RETURN EVENT, otherwise Sidebar, Translate will not works
                                return event
                            }
                        }
                        else {
                            // MUST RETURN EVENT, otherwise Sidebar, Translate will not works
                            return event
                        }
                    }
                )
            )
            .pipe(
                catchError((error) => {

                    if (error instanceof HttpErrorResponse) {
                        if (error.error instanceof ErrorEvent) {
                            console.error("Error Event");
                        } else {
                            // Swal.fire("HTTP Error: " + error.status)
                            switch (error.status) {
                                case 401:      //login
                                    const shallowClone = { ...error };
                                    // console.error("shallowClone: " + JSON.stringify(shallowClone));
                                    shallowClone.message = "Please login."
                                    shallowClone.error = "Please login."
                                    // console.error("shallowClone message: " + JSON.stringify(shallowClone));
                                    error = shallowClone
                                    // let copy = Object.assign({}, error)
                                    // copy.message = "abc";
                                    // let newErr = error.clone()
                                    // console.error("Error: " + JSON.stringify(error));
                                    // this.layoutService.emitHomeModalChange('Login');
                                    // if (CommonFunction.IsAppPlatform) {
                                    //     this.authService.shopLogout();
                                    // } else {
                                    this.authService.logout(true);
                                    // }
                                    break;
                                // this.router.navigateByUrl("/pages/login");
                                // break;
                                case 403:     //forbidden
                                    if (error.error == "Session expired due to duplicate login at different location.") {
                                        // if (CommonFunction.IsAppPlatform) {
                                        //     this.authService.shopLogout();
                                        // } else {
                                        this.authService.logout(true);
                                        // }
                                        // Swal.fire("Session expired. Please login.")
                                        // this.router.navigateByUrl("/");
                                    } else if (error.error == "Subscription expired.") {
                                        this.router.navigateByUrl("/dashboard/landing");
                                    }
                                    break;
                            }
                        }
                    } else {
                        console.error("some thing else happened");
                    }

                    return throwError(error);
                })
            );

        //         return next.handle(req).pipe(
        //             tap(evt => {
        //                 if (evt instanceof HttpResponse) {
        //                     if (evt.body) {

        //                         let result: ServiceResponse<any> = JSON.parse(JSON.stringify(evt.body));

        //                         if (result.Result != null) {
        //                             if(result.Result == 0) {

        //                             } else {

        //                                 return evt.clone({
        //                                     body: result.ResponseResult
        //                                 });
        //                             }
        //                         }
        //                     }
        //                     /**
        //                      * export class ServiceResponse<T>
        // {
        // result: Result;
        // responseResult: T;
        // errorCode?: number;
        // message: string;
        // }

        // export enum Result {
        // Failed = 0,
        // Succeeded = 1
        // }

        //                      */

        //                     // this.toasterService.success(evt.body.success.message, evt.body.success.title, { positionClass: 'toast-bottom-center' });
        //                     // }
        //                 }
        //             }),
        //             catchError((error) => {

        //                 if (error instanceof HttpErrorResponse) {
        //                     if (error.error instanceof ErrorEvent) {
        //                         console.error("Error Event");
        //                     } else {
        //                         switch (error.status) {
        //                             case 401:      //login
        //                                 const shallowClone = { ...error };
        //                                 // console.error("shallowClone: " + JSON.stringify(shallowClone));
        //                                 shallowClone.message = "Please login."
        //                                 shallowClone.error = "Please login."
        //                                 // console.error("shallowClone message: " + JSON.stringify(shallowClone));
        //                                 error = shallowClone
        //                                 // let copy = Object.assign({}, error)
        //                                 // copy.message = "abc";
        //                                 // let newErr = error.clone()
        //                                 // console.error("Error: " + JSON.stringify(error));
        //                                 this.layoutService.emitHomeModalChange('Login');
        //                                 this.authService.logout();
        //                                 break;
        //                             // this.router.navigateByUrl("/pages/login");
        //                             // break;
        //                             case 403:     //forbidden
        //                                 this.router.navigateByUrl("/pages/unauthorized");
        //                                 break;
        //                         }
        //                     }
        //                 } else {
        //                     console.error("some thing else happened");
        //                 }

        //                 return throwError(error);
        //             })
        //         )
    }
}