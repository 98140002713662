import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'setting',
    loadChildren: () => import('../../setting/setting.module').then(m => m.SettingModule)
  },
];
