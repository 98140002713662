import { Pipe, PipeTransform } from '@angular/core';
import { add } from 'date-fns';

@Pipe({
  name: 'receiptAddressFilter'
})
export class ReceiptAddressPipe implements PipeTransform {
  transform(value: string): any {
    let compName = null;

    if (value) {
      let oriLines = value.split('\n');
      let lines;

      let addrs = ['lot ', 'jln ', 'jalan ', 'tmn ', 'taman ', 'kuala lumpur', 'sabah', 'sarawak', 'johor', 'melaka', 'seremban', 'kuantan', 'penang', 'perak', 'perlis', 'selangor', 'terengganu']
      let postCodeReg = '[0-9]{5} |kl$|k.l$';

      if (oriLines) {
        lines = oriLines.filter(x => {
          if (x.toLowerCase().match(postCodeReg) != null) {
            return true
          }

          for (var rec of addrs) {
            if (x.toLowerCase().indexOf(rec) >= 0) {
              return true
            }
          }

          return false;
        });

        if (lines && lines.length > 0) {
          compName = lines.join('\n');
        }
        // else {
        //   lines = oriLines.filter(x => x.toLowerCase().indexOf("petronas") >= 0 || x.toLowerCase().indexOf("shell") >= 0 ||
        //     x.toLowerCase().indexOf("caltex") >= 0 || x.toLowerCase().indexOf("petron") >= 0 || x.toLowerCase().indexOf("bakery") >= 0 ||
        //     x.toLowerCase().indexOf("dewan bandaraya kuala lumpur") >= 0 || x.toLowerCase().indexOf("klinik") >= 0 || x.toLowerCase().indexOf("clinic") >= 0 ||
        //     x.toLowerCase().indexOf("kfc") >= 0 || x.toLowerCase().indexOf("sst") >= 0 || x.toLowerCase().indexOf(" mall") >= 0 ||
        //     x.toLowerCase().indexOf("center") >= 0 || x.toLowerCase().indexOf("centre") >= 0);

        //   if (lines && lines.length > 0) {
        //     compName = lines.join(',\n');
        //   } else {
        //     lines = oriLines.filter(x => x.toLowerCase().indexOf("invoice id") >= 0 || x.toLowerCase().indexOf("sales id") >= 0 ||
        //       x.toLowerCase().indexOf(" id") >= 0);

        //     if (lines && lines.length > 0) {
        //       compName = lines.join(',\n');
        //     }
        //   }
        // }
      }
    }

    return compName;
  }
}
