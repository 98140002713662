<div class="container">
  <div class="row mb-2">
    <div class="col" (click)="writeValue(1)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">1</a></div>
    <div class="col" (click)="writeValue(2)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">2</a></div>
    <div class="col" (click)="writeValue(3)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">3</a></div>
    <div class="col" (click)="setFixValue(1000)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">10.00</a></div>
  </div>
  <div class="row mb-2">
    <div class="col" (click)="writeValue(4)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">4</a></div>
    <div class="col" (click)="writeValue(5)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">5</a></div>
    <div class="col" (click)="writeValue(6)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">6</a></div>
    <div class="col" (click)="setFixValue(5000)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">50.00</a></div>
  </div>
  <div class="row mb-2">
    <div class="col" (click)="writeValue(7)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">7</a></div>
    <div class="col" (click)="writeValue(8)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">8</a></div>
    <div class="col" (click)="writeValue(9)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">9</a></div>
    <div class="col" (click)="setFixValue(10000)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">100.00</a></div>
  </div>
  <div class="row">
    <div class="col" (click)="clearOneDigit()"><a class="btn btn-raised mr-1 btn-info btn-lg btn-block">⌫</a></div>
    <div class="col" (click)="writeValue(0)"><a class="btn btn-raised mr-1 btn-success btn-lg btn-block">0</a></div>
    <div class="col" (click)="complete()"><a [class]="enableCheckout ? 'btn btn-raised mr-1 btn-success btn-lg btn-block' : 'btn btn-block btn-lg btn-light btn-raised mr-1'">=</a></div>
    <div class="col" (click)="clear()"><a class="btn btn-raised mr-1 btn-danger btn-lg btn-block">CLEAR</a></div>
  </div>
</div>