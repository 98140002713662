export enum LocalStorageType {
  Session = 'session',

  CompanyList = 'company_list',
  DynamicFormList = 'dynamic_form_list',
  DepartmentList = 'department_list',
  EmployeeList = 'employee_list',
  LevelList = 'level_list',
  PositionList = 'position_list',
  CategoryList = 'category_list',
  TaxList = 'tax_list',
  Preview = 'preview',
  Dynamic = 'dynamic',
  DynamicFormTemplateList = 'dynamic_form_template_list',
  CompanyRecordList = 'company_record_list',
  CompanyRefMemberList = 'company_ref_member_list',
  MemberList = 'member_list',
  AccessToken = 'session_id',
  AttachmentList = 'attachment_list',
  ProductImageList = 'product_image_list',
  // ProductList = 'product_list',
  Member = 'member',
  Employee = 'employee',
  CompanyInfo = 'company_info',
  CompanyID = 'c_session_id',
  PreviousUser = 'previous_user',
  QRCode = 'QR_code',
  MenuCategory = 'menu_category',
  TypeList = 'type_list',
  RefMember = 'ref_member',
  Language = 'language',
  TemplateDepartmentList = 'template_department_list',
  TemplateLevelList = 'template_level_list',
  TemplatePositionList = 'template_position_list',
  DynamicFormListRecording = 'dynamic_form_list_recording',
  CompanyFormList = 'company_form_list',
  POSTransactionList = 'pos_transaction_list',
  POSPendingTransactionList = 'pending_pos_transaction_list',
  POSDashboardPendingUploadList = 'pos_dashboard_pending_upload',
  POSDashboardTodayTransactionList = 'pos_dashboard_today_transaction',
  QuotationDashboardPendingUploadList = 'quotation_dashboard_pending_upload',
  QuotationDashboardTodayTransactionList = 'quotation_dashboard_today_transaction',
  PurchaseOrderDashboardPendingUploadList = 'purchase_order_dashboard_pending_upload',
  PurchaseOrderDashboardTodayTransactionList = 'purchase_order_dashboard_today_transaction',
  PurchaseOrderPendingTransactionList = 'purchase_order_dashboard_pending_upload',

  // eCommerce
  ShoppingCart = 'shopping_cart',
  eCommerceHomeProduct = 'ecommerce_home_product',
  eCommerceProductList = 'ecommerce_product_list',

  eCommerceProductListSellerCounter = 'ecommerce_product_list_seller_counter',
  eCommerceProductListSeller = 'ecommerce_product_list_seller',
  eCommerceOrderListSeller = 'ecommerce_order_list_seller',
  eCommerceVoucherListSeller = 'ecommerce_voucher_list_seller',

  LastRequestEmailCodeTime = 'last_request_email_code_time',
  LastRequestPhoneCodeTime = 'last_request_email_phone_time',

  COVID19User = 'covid_user',
  COVID19Company = 'covid_company',
  COVID19UserPwd = 'covid_user_pwd',
  COVID19CompanyPwd = 'covid_company_pwd',

  // POS
  POSCart = 'pos_cart',

  Landing_SelectedSystem = 'landing_selected_sys',

  EmployeeModule = 'employee_module',

  SupplierList = 'supplier_list',
  CustomerList = 'customer_list',
  CustomerOrderList = 'customer_order_list',
  CustomerDeliveryList = 'customer_delivery_list',
  SupplierDeliveryList = 'supplier_delivery_list',
  CustomerQuotationList = 'customer_quotation_list',
  SupplierQuotationList = 'supplier_quotation_list',
  QRStockDetailList = 'qr_stock_detail_list',

  Announcement = 'announcement',
  AuditLogList = 'audit_log_list',
  CompanyMemberList = 'company_member_list',
  CompanyEventList = 'company_event_list',
  ProjectList = 'company_project_list',
  CompanyMemberPointList = 'company_member_point_list',
  CompanyMemberPointRedemptionList = 'company_member_point_redemption_list',
  NewsList = 'company_news_list',
  ShopName = 'shop_name',
  ShopCompany = 'shop_company',
  ScanningProduct = 'scanning_product',

  CachedCustomerDeliveryList = 'cache_delivery',
  CachedOrderList = 'cache_order',
  CachedProductFormulaList = 'cache_product_formula',
  CachedProductProcessList = 'cache_product_process',
  OrderCart = 'order_cart',
  SupplierOrderList = 'supplier_order_list',
  CachedSupplierOrderList = 'cache_supplier_order',
  SupplierOrderCart = 'supplier_order_cart',
  CachedSupplierDeliveryList = 'cache_supplier_delivery',
  SupplierReturnList = 'supplier_return_list',
  CachedSupplierReturnList = 'cache_supplier_return',
  CustomerReturnList = 'customer_return_list',
  CachedCustomerReturnList = 'cache_customer_return',
  ProductFormulaList = 'product_formula_list',
  ProductProcessList = 'product_process_list',
  ProductProcessCheckInList = 'product_process_check_in_list',

  StockList = 'stock_list',

  // SupplierListCount = 'supplier_list_count',
  // CustomerListCount = 'customer_list_count',
  // CustomerOrderListCount = 'customer_order_list_count',
  // SupplierOrderListCount = 'supplier_order_list_count',
  // CustomerDeliveryListCount = 'customer_delivery_list_count',
  // SupplierDeliveryListCount = 'supplier_delivery_list_count',
  // CustomerReturnListCount = 'customer_return_list_count',
  // SupplierReturnListCount = 'supplier_return_list_count',

  CompanyMemberTreatmentList = 'company_member_treatment_list',

  CachedProductProcessCheckInList = 'cache_product_process_check_in_list',

  AgentList = 'agent_list',
  BookingList = 'booking_list',
  BookingTypeList = 'booking_type_list',

  ISOFormList = 'iso_form_list',

  ProductList = 'product_list',
  
  CachedSupplierDelivery = 'cache_supplier_delivery',  
  CachedSupplierReturn = 'cache_supplier_return',
  CachedSupplierReturnDelivery = 'cache_supplier_return_delivery',
  CachedProductProcess = 'cache_product_process',  
  CachedCustomerDelivery = 'cache_customer_delivery',  
  CachedCustomerReturn = 'cache_customer_return',
  CachedCustomerReturnDelivery = 'cache_customer_return_delivery',
}

export class YesNoSelection {
  Name: string;
  DataValue: boolean;

  public constructor(init?: Partial<YesNoSelection>) {
    Object.assign(this, init);
  }
}

export enum RecordAction {
  Create = 0,
  View = 1,
  Edit = 2,
  Delete = 3
}

export class LanguageData {
  en: string;
  cn: string;
  my: string;
}