import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

// import { AngularFireModule } from "@angular/fire";
// import { AngularFireAuthModule } from "@angular/fire/auth";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { DeviceDetectorModule } from 'ngx-device-detector';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from 'ngx-spinner';

import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentNavLayoutComponent } from "./layouts/content-nav/content-nav-layout.component";
import { BillingLayoutComponent } from "./layouts/billing/billing-layout.component";
import { DeviceLayoutComponent } from "./layouts/device/device-layout.component";
import { InventoryLayoutComponent } from "./layouts/inventory/inventory-layout.component";
import { PublicLayoutComponent } from "./layouts/public/public-layout.component";
import { CompanyLayoutComponent } from "./layouts/company/company-layout.component";

import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from './shared/services/window.service';

import { NgSelectModule } from '@ng-select/ng-select';

import { GlobalHttpInterceptorService } from './shared/services/interceptor.service';
import { ResizeService } from "./shared/services/resize.service";
import { ISOLayoutComponent } from "./layouts/iso/iso-layout.component";

// var firebaseConfig = {
//   apiKey: "YOUR_API_KEY", //YOUR_API_KEY
//   authDomain: "YOUR_AUTH_DOMAIN", //YOUR_AUTH_DOMAIN
//   databaseURL: "YOUR_DATABASE_URL", //YOUR_DATABASE_URL
//   projectId: "YOUR_PROJECT_ID", //YOUR_PROJECT_ID
//   storageBucket: "YOUR_STORAGE_BUCKET", //YOUR_STORAGE_BUCKET
//   messagingSenderId: "YOUR_MESSAGING_SENDER_ID", //YOUR_MESSAGING_SENDER_ID
//   appId: "YOUR_APP_ID", //YOUR_APP_ID
//   measurementId: "YOUR_MEASUREMENT_ID" //YOUR_MEASUREMENT_ID
// };


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent, 
    FullLayoutComponent, 
    ContentLayoutComponent, 
    ContentNavLayoutComponent, 
    BillingLayoutComponent, 
    DeviceLayoutComponent, 
    ISOLayoutComponent, 
    InventoryLayoutComponent, 
    PublicLayoutComponent,
    CompanyLayoutComponent
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    // AngularFireModule.initializeApp(firebaseConfig),
    // AngularFireAuthModule,
    NgbModule,
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR_GOOGLE_MAP_API_KEY"
    }),
    PerfectScrollbarModule,
    NgSelectModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    WINDOW_PROVIDERS,
    ResizeService,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true  },  // Global handle on HTTP error
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
