import { Component, EventEmitter, forwardRef, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-filter-bar',
  template: `<div class="input-group">
  <input type="text" class="form-control bg-white" placeholder="{{'Search for...' | translate}}"
      [(ngModel)]="value" (keydown.enter)="filterData()">
  <div class="input-group-append">
      <button class="btn btn-raised btn-primary" type="button" (click)="filterData()">{{'Go!'
          | translate}}</button>
  </div>
</div>`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterBarComponent),
      multi: true
    }
  ]
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class FilterBarComponent implements ControlValueAccessor {

  @Output() filter: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  filterData() {
    this.filter.emit();
  }

  private _val: string; // this is the updated value that the class accesses
  disabled: boolean;
  isSubmitting = false;

  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this._val = val;

    this.onChange(val)
    this.onTouch(val)
  }

  get value(): string {
    return this._val;
  }

  onChange: any = () => { }
  onTouch: any = () => { }

  // this method sets the value programmatically
  writeValue(value: any): void {
    this.value = value
  }

  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  /**    
  * This function is called when the control status changes to or from "DISABLED".
  * Depending on the value, it will enable or disable the appropriate DOM element.
  * @param isDisabled
  */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
