// import { AES, enc, mode, pad, HmacSHA256 } from 'crypto-js';
import * as CryptoJS from 'crypto-js';
import * as bcrypt from 'bcryptjs';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { formatDate } from '@angular/common';

export class CommonFunction {

  static GenGUID(): string {
    function id() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return id() + id() + '-' + id() + '-' + id() + '-' +
      id() + '-' + id() + id() + id();
  }

  static encryptCompany(data) {
    var key = CryptoJS.enc.Utf8.parse('n8e8x8t8d8a8t8a8');
    var iv = CryptoJS.enc.Utf8.parse('k2r9sh3221gqutco');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return btoa(encrypted.toString());
  }

  static decryptCompany(data) {
    var key = CryptoJS.enc.Utf8.parse('n8e8x8t8d8a8t8a8');
    var iv = CryptoJS.enc.Utf8.parse('k2r9sh3221gqutco');

    const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted;
  }

  static encryptPayment(data) {
    var key = CryptoJS.enc.Utf8.parse('n8e8x8t8d8a8t8a8');
    var iv = CryptoJS.enc.Utf8.parse('kyr9phm22ngqttce');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return btoa(encrypted.toString());
  }

  static decryptPayment(data) {
    var key = CryptoJS.enc.Utf8.parse('n8e8x8t8d8a8t8a8');
    var iv = CryptoJS.enc.Utf8.parse('kyr9phm22ngqttce');

    const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted;
  }

  static hashPassword(password: string) {
    const salt = bcrypt.genSaltSync(10);
    return bcrypt.hashSync(password, salt);
  }

  static encryptMember(data) {
    var key = CryptoJS.enc.Utf8.parse('SgVkXp2s5v8y/B?E');
    var iv = CryptoJS.enc.Utf8.parse('NcRfUjXn2r4u7x!A');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return btoa(encrypted.toString());
  }

  static decryptMember(data) {
    var key = CryptoJS.enc.Utf8.parse('SgVkXp2s5v8y/B?E');
    var iv = CryptoJS.enc.Utf8.parse('NcRfUjXn2r4u7x!A');

    const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted;
  }

  static encryptCovid(data) {
    var key = CryptoJS.enc.Utf8.parse('CgVXX1!63v8y/B?E');
    var iv = CryptoJS.enc.Utf8.parse('NcRx83kf9g4u7x!A');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return btoa(encrypted.toString());
  }

  static decryptCovid(data) {
    var key = CryptoJS.enc.Utf8.parse('CgVXX1!63v8y/B?E');
    var iv = CryptoJS.enc.Utf8.parse('NcRx83kf9g4u7x!A');

    const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted;
  }

  static encryptQRCovid(data) {
    var key = CryptoJS.enc.Utf8.parse('XKS*idU82X8y/B?E');
    var iv = CryptoJS.enc.Utf8.parse('Ncx133kf9g4u7x!A');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return btoa(encrypted.toString());
  }

  static decryptQRCovid(data) {
    var key = CryptoJS.enc.Utf8.parse('XKS*idU82X8y/B?E');
    var iv = CryptoJS.enc.Utf8.parse('Ncx133kf9g4u7x!A');

    const decrypted = CryptoJS.AES.decrypt(atob(data), key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  static encryptPOS(data) {
    var key = CryptoJS.enc.Utf8.parse('!KS*id@82C8?/2?8');
    var iv = CryptoJS.enc.Utf8.parse('Ncx188k@144*as!A');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return btoa(encrypted.toString());
  }

  static decryptPOS(data) {
    var key = CryptoJS.enc.Utf8.parse('!KS*id@82C8?/2?8');
    var iv = CryptoJS.enc.Utf8.parse('Ncx188k@144*as!A');

    const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted;
  }

  static encryptProduct(data) {
    var key = CryptoJS.enc.Utf8.parse('!KS*i9132%2?/2?8');
    var iv = CryptoJS.enc.Utf8.parse(')2312Fw%144*as!A');

    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return btoa(encrypted.toString());
  }

  static decryptProduct(data) {
    var key = CryptoJS.enc.Utf8.parse('!KS*i9132%2?/2?8');
    var iv = CryptoJS.enc.Utf8.parse(')2312Fw%144*as!A');

    const decrypted = CryptoJS.AES.decrypt(data, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    return decrypted;
  }

  static resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (height / width > 2 || width / height > 2) {
          newHeight = height * 0.9;
          newWidth = width * 0.9;
        } else if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');

        context.drawImage(image, 0, 0, newWidth, newHeight);

        canvas.toBlob(resolve, file.type);
      };
      image.onerror = reject;
    });
  }

  static GenRandomNumber(length: number): string {
    return Math.random().toString().slice(2, 2 + length);
  }

  static compressImage(src, maxWidthHeight = 1024) {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        let ratio = 0;

        if (img.width > img.height) {
          // Landscape image
          ratio = img.width / maxWidthHeight;
        } else {
          // Portrait image
          ratio = img.height / maxWidthHeight;
        }

        const newWidth = img.width / ratio;
        const newHeight = img.height / ratio;

        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL("image/jpeg");
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  static IsCKEPage(pageType) {
    let isCKEFormat = false;

    switch (pageType) {
      case 'MR Policy':
      case 'MR Manual':
      case 'Proposal':
      case 'MR Form':
      case 'MR List':
        isCKEFormat = true;
        break;
      default:
        isCKEFormat = false;
        break;
    }

    return isCKEFormat;
  }

  static GetRouterName(router: Router): string {
    let name = '';

    if (router.url.indexOf('/iso/') >= 0) {
      name = '/iso'
    } else if (router.url.indexOf('/device/') >= 0) {
      name = '/device'
    } else if (router.url.indexOf('/inventory/') >= 0) {
      name = '/inventory'
    } else if (router.url.indexOf('/company/') >= 0) {
      name = '/company'
    } else {
      name = '/billing'
    }

    return name;
  }

  static GetPageName(router: Router): string {
    return router.url.split('/')[2];
  }

  static roundToDecimalPoint(actualValue, decimalPoint = 3) {
    return Number((Math.round((actualValue) * 1000) / 1000).toFixed(decimalPoint))
  }

  static fileNameValidator(name) {
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    let matchResult = name.match(/[\\\/:\*\?"<>\|]/);

    console.log(matchResult);
    
    if (matchResult) {
      return false;
    } else {
      return true;
    }
  }

  static GetPrintSampleData(authService: AuthService): any[] {
    let documents = [
      {
        ID: 'sales-quotation',
        Name: 'Quotation',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Quotation',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated quotation. No signature required.'
  
        }
      },
      {
        ID: 'sales-quotation-dotmatrix',
        Name: 'Quotation (Dot Matrix)',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Quotation (Dot Matrix)',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated quotation. No signature required.'
  
        }
      },
      {
        ID: 'sales-order',
        Name: 'Order',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Order',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SO12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'sales-order-dotmatrix',
        Name: 'Order (Matrix)',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Order',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'sales-invoice',
        Name: 'Invoice',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Invoice',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'sales-delivery',
        Name: 'Delivery',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Delivery',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SD12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'sales-receipt',
        Name: 'Receipt',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Sales Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Receipt',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SR12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'purchase-quotation',
        Name: 'Quotation',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Purchase Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Quotation',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated quotation. No signature required.'
  
        }
      },
      {
        ID: 'purchase-order',
        Name: 'Order',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Purchase Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Order',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'purchase-invoice',
        Name: 'Invoice',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Purchase Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Invoice',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SI12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'purchase-delivery',
        Name: 'Delivery',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Purchase Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Delivery',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SD12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'purchase-receipt',
        Name: 'Receipt',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: true,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Item',
          HeaderQty: 'Unit',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Ref No',
          HeaderSubtotal: 'Subtotal',
          HeaderRounding: 'Rounding',
          HeaderSalesPerson: 'Purchase Person',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Receipt',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          InvoiceNo: 'SR12345678',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Subtotal: '10',
          Rounding: '0',
          GrandTotal: '10',
          Declaration: 'This is computer generated invoice. No signature required.'
  
        }
      },
      {
        ID: 'production',
        Name: 'Production',
        Default: {
          XOffset: 60,
          YOffset: 20,
          Width: 0,
          Height: 0,
          BottomReserved: 50,
          ShowCompany: true,
          ShowCode: false,
          ShowQRCode: false,
          ShowFooter: true,
          BlackAndWhite: false,
          ShowUnitName: true,
          IsStripedRow: true,
          ID: 'TestingID',
          HeaderNo: 'No',
          HeaderCode: 'Code',
          HeaderDesc: 'Material',
          HeaderQty: 'Qty',
          HeaderUnitPrice: 'Unit Price',
          HeaderTotal: 'Total',
          HeaderNotes: 'Notes',
          HeaderDate: 'Date',
          HeaderInvoice: 'Formula',
          HeaderRemark: 'Remark',
          HeaderStartTime: 'Start Time',
          HeaderEndTime: 'End Time',
          HeaderTimeUsed: 'Time Used',
          Notes: 'Testing',
          IssuerAddressLine1: authService.getCompanyInfo().Address1,
          IssuerAddressLine2: authService.getCompanyInfo().Address2,
          IssuerPostCode: authService.getCompanyInfo().PostCode,
          IssuerState: authService.getCompanyInfo().State,
          IssuerContactNo: authService.getCompanyInfo().ContactNo,
          IssuerCompanyName: authService.getCompanyInfo().CompanyName,
          IssuerCompanyRegNo: authService.getCompanyInfo().License,
          Invoice: 'Production',
          InvoiceDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
          Formula: 'FM021566',
          Remark: 'Remark',
          AddressLine1: 'Address 1',
          ContactNo: 'Receiver Name Contact No',
          CompanyName: 'Company Name',
          Items: [
            {
              Desc: 'Desc',
              UnitPrice: 5,
              Qty: 2,
              Total: 10,
              UnitName: 'PCS',
              Code: 'Code',
              Remark: 'Remark',
            },
          ],
          Declaration: 'This is computer generated quotation. No signature required.'
  
        }
      },
    ]

    return documents
  }
}