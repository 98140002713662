import { formatDate } from '@angular/common';
import { cmyk, PageSizes, PDFDocument, PDFPage, rgb, StandardFonts } from 'pdf-lib';
import * as kjua from 'kjua-svg';
import { environment } from 'environments/environment';
import { _ } from 'core-js';
import * as fontkit from '@btielen/pdf-lib-fontkit';

export class CommonPrint {

    static TextToLineArray(longData, addressMaxLen = 50): string[] {
        var finalLines = [];

        if (longData) {
            if (!isNaN(longData)) {
                finalLines.push(longData)
            } else {
                var oriLines = longData.split(/\r\n|\r|\n/)

                var result = "";
                var lines = [];
                // const addressMaxLen = 50;
                const wsLookup = 15; // Look backwards n characters for a whitespace
                const regex = new RegExp(String.raw`\s*(?:(\S{${addressMaxLen}})|([\s\S]{${addressMaxLen - wsLookup},${addressMaxLen}})(?!\S))`, 'g');

                for (var oriLine of oriLines) {
                    result = oriLine.replace(regex, (_, x, y) => x ? `${x}-\n` : `${y}\n`)
                    lines = result.split(/\r\n|\r|\n/)

                    for (var newLine of lines) {
                        // Push if not empty line
                        if (newLine) {
                            finalLines.push(newLine)
                        }
                    }
                }
            }
        }

        return finalLines
    }

    static formatMoney(n) {
        return "" + (Math.round(n * 100) / 100).toLocaleString(undefined, { minimumFractionDigits: 2 });
    }

    static AddVerticalCenterMultiLineText(lines: string[], page, x, y, dataLineHeight, dataTextSize) {

        var headerAddressTextHeight = lines.length * dataLineHeight;    // 6 * 12 = 72
        var addressTextCenterPoint = headerAddressTextHeight / 2;       // 60 / 2 = 30

        var i = 1;
        var lineY = 0;

        for (var line of lines) {
            lineY = y - i * dataLineHeight + addressTextCenterPoint; // 
            page.drawText(line, {
                x: x,
                y: lineY,
                maxWidth: 230,
                lineHeight: dataLineHeight,
                size: dataTextSize,
                // color: color,
                // font: font
            })

            i++;
        }
    }

    static AddHorizontalVerticalCenterMultiLineText(lines: string[], page, x, y, dataLineHeight, dataTextSize, font, maxWidth = 230) {

        var headerAddressTextHeight = lines.length * dataLineHeight;    // 6 * 12 = 72
        var addressTextCenterPoint = headerAddressTextHeight / 2;       // 60 / 2 = 30

        var i = 1;
        var lineY = 0;

        for (var line of lines) {
            let textWidth = font.widthOfTextAtSize(line, dataTextSize);

            lineY = y - i * dataLineHeight + addressTextCenterPoint; // 
            page.drawText(line, {
                x: (maxWidth - textWidth) / 2,
                y: lineY,
                maxWidth: maxWidth,
                lineHeight: dataLineHeight,
                size: dataTextSize,
                // color: color,
                // font: font
            })

            i++;
        }
    }

    static AddHorizontalCenterMultiLineText(lines: string[], page, x, y, dataLineHeight, dataTextSize, font, maxWidth = 230, color = null) {
        var i = 0;
        var lineY = 0;

        for (var line of lines) {
            let textWidth = font.widthOfTextAtSize(line, dataTextSize);

            lineY = y - i * dataLineHeight; // 

            if (color) {
                page.drawText(line, {
                    x: (maxWidth - textWidth) / 2,
                    y: lineY,
                    maxWidth: maxWidth,
                    lineHeight: dataLineHeight,
                    size: dataTextSize,
                    color: color,
                })
            } else {
                page.drawText(line, {
                    x: (maxWidth - textWidth) / 2,
                    y: lineY,
                    maxWidth: maxWidth,
                    lineHeight: dataLineHeight,
                    size: dataTextSize,
                })
            }

            i++;
        }
    }

    static AddHorizontalCenterMultiLineInvertText(lines: string[], page, x, y, dataLineHeight, dataTextSize, font, maxWidth = 230) {
        var i = 0;
        var lineY = 0;

        for (var line of lines) {
            let textWidth = font.widthOfTextAtSize(line, dataTextSize);

            lineY = y - i * dataLineHeight; // 

            // page.drawRectangle({
            //     x: x,
            //     y: y - 2,
            //     width: maxWidth,
            //     height: dataLineHeight,
            //     // borderColor: rgb(0, 0, 0),
            //     color: rgb(0, 0, 0),
            //     // borderWidth: 1.5,
            //   })

            page.drawText(line, {
                x: (maxWidth - textWidth) / 2 + x,
                y: lineY,
                maxWidth: maxWidth,
                lineHeight: dataLineHeight,
                size: dataTextSize,
                // color: rgb(1, 1, 1),
            })

            i++;
        }
    }

    // static AddPOSMultiLineText(lines: string[], page, x, y, dataLineHeight, dataTextSize, maxWidth = 230) {
    //     var i = 0;
    //     var lineY = 0;

    //     for (var line of lines) {
    //         lineY = y - i * dataLineHeight; // 
    //         page.drawText(line, {
    //             x: x,
    //             y: lineY,
    //             maxWidth: maxWidth,
    //             lineHeight: dataLineHeight,
    //             size: dataTextSize,
    //             // color: color,
    //             // font: font
    //         })

    //         i++;
    //     }
    // }

    static AddMultiLineText(isPOS: boolean, lines: string[], maxWidth, page, font, x, y, dataLineHeight, dataTextSize, color = cmyk(0, 0, 0, 1)) {
        var i = 0;
        var lineX = 0;

        for (var line of lines) {
            if (!line)
                continue;

            lineX = y - i * dataLineHeight;

            if (isPOS) {
                page.drawText(line, {
                    x: x,
                    y: lineX,
                    maxWidth: maxWidth,
                    lineHeight: dataLineHeight,
                    size: dataTextSize,
                    font: font
                })

            } else {
                page.drawText(line, {
                    x: x,
                    y: lineX,
                    maxWidth: maxWidth,
                    lineHeight: dataLineHeight,
                    size: dataTextSize,
                    color: color,
                    font: font
                })
            }

            i++;
        }
    }

    static fillParagraph(text, font, fontSize, maxWidth) {
        const paragraphs = text.split('\n')

        for (let index = 0; index < paragraphs.length; index++) {
            const paragraph = paragraphs[index]

            if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
                const words = paragraph.split(' ')
                const newParagraph: string[][] = []
                let i = 0
                newParagraph[i] = []

                for (let k = 0; k < words.length; k++) {
                    const word = words[k]
                    newParagraph[i].push(word)
                    
                    if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth) {
                        newParagraph[i].splice(-1) // retira a ultima palavra
                        i = i + 1
                        newParagraph[i] = []
                        newParagraph[i].push(word)
                    }
                }

                paragraphs[index] = newParagraph.map((p) => p.join(' ')).join('\n')
            }
        }
        return paragraphs.join('\n')
        
        // var paragraphs = text.split('\n');
        // for (let index = 0; index < paragraphs.length; index++) {
        //     var paragraph = paragraphs[index];
        //     if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
        //         var words = paragraph.split(' ');
        //         var newParagraph = [];
        //         var i = 0;
        //         newParagraph[i] = [];
        //         for (let k = 0; k < words.length; k++) {
        //             var word = words[k];
        //             newParagraph[i].push(word);
        //             if (font.widthOfTextAtSize(newParagraph[i].join(' '), fontSize) > maxWidth) {
        //                 newParagraph[i].splice(-1); // retira a ultima palavra
        //                 i = i + 1;
        //                 newParagraph[i] = [];
        //                 newParagraph[i].push(word);
        //             }
        //         }
        //         paragraphs[index] = newParagraph.map(p => p.join(' ')).join('\n');
        //     }
        // }
        // return paragraphs.join('\n');
    }

    // static AddAlignRightText(data: string, page, font, width, height, xOffset, yOffset, dataLineHeight, dataTextSize) {
    //     let textWidth = font.widthOfTextAtSize(data, dataTextSize);

    //     page.drawText(data, {
    //         x: width - textWidth - xOffset,
    //         y: height - yOffset,
    //         size: dataTextSize,
    //         lineHeight: dataLineHeight
    //         // color: color,
    //         // font: font
    //     })
    // }

    static AddAlignRightTextNew(isPOS: boolean, data: string, page, font, x, currentY, dataLineHeight, dataTextSize, color = cmyk(0, 0, 0, 1)) {
        let textWidth = font.widthOfTextAtSize(data, dataTextSize);

        if (isPOS) {
            page.drawText(data, {
                x: x - textWidth,
                y: currentY,
                size: dataTextSize,
                lineHeight: dataLineHeight,
                font: font
            })

        } else {
            page.drawText(data, {
                x: x - textWidth,
                y: currentY,
                size: dataTextSize,
                lineHeight: dataLineHeight,
                color: color,
                font: font
            })

        }

        return currentY -= dataLineHeight;
    }

    // static InsertTableLine(data: string[], width: number[], alignRight: boolean[], page, font, currentX, currentY, tableLineHeight, dataTextSize, color = cmyk(0, 0, 0, 1)) {
    //     // Prepare for new line
    //     // var currentX = xOffset + tableXOffset
    //     var currentRowLine = 1

    //     for (var i = 0; i < data.length; i++) {
    //         // Calculate maximum length
    //         let textWidth = font.widthOfTextAtSize("A", dataTextSize);
    //         let maxChar = Math.floor(width[i] / textWidth);

    //         console.log('maxChar: ' + maxChar)

    //         var lineData = this.TextToLineArray(data[i], maxChar);

    //         console.log('length: ' + lineData.length + ',lineData: ' + lineData)

    //         // Take most line
    //         if (lineData.length > currentRowLine) {
    //             currentRowLine = lineData.length;
    //         }

    //         // If align Right, need to add Width before write data
    //         if (alignRight[i]) {
    //             currentX += width[i]
    //             for(var rec of lineData) {
    //                 this.AddAlignRightTextNew(false, rec, page, font, currentX, currentY, tableLineHeight, dataTextSize, color);

    //                 currentY -= tableLineHeight;
    //             }
    //         } else {
    //             this.AddMultiLineText(false, lineData, width[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color);
    //             currentX += width[i]
    //         }
    //     }

    //     return currentRowLine;
    // }

    static InsertTableLine(isPOS: boolean, data: string[], width: number[], alignRight: boolean[], page, font, currentX, currentY, tableLineHeight, dataTextSize, color = cmyk(0, 0, 0, 1)) {
        // Prepare for new line
        // var currentX = xOffset + tableXOffset
        var currentRowLine = 1

        for (var i = 0; i < data.length; i++) {
            let newLines = data[i].split('\n');//.forEach(paragraph => page.drawText(paragraph));

            let textWidth = 0;

            // Calculate maximum length
            if(newLines.length > 1) {
                var longest = newLines.sort(
                    function (a, b) {
                        return b.length - a.length;
                    }
                )[0];

                textWidth = font.widthOfTextAtSize(longest, dataTextSize);
            } else {
                textWidth = font.widthOfTextAtSize(data[i], dataTextSize);
            }

            let maxChar = 50;

            if (textWidth > width[i]) {
                textWidth = textWidth / data[i].length;
                maxChar = Math.floor(width[i] / textWidth);

                // console.log('textWidth: ' + textWidth)
                // console.log('width[i]: ' + width[i])
                // console.log('maxChar: ' + maxChar)
            }

            var lineData = this.TextToLineArray(data[i], maxChar);
            
            if(newLines.length > 1) {
                lineData = [];

                for(var l of newLines) {
                    lineData =lineData.concat(this.TextToLineArray(l, maxChar));
                }
            }

            // console.log('lineData: len: ' + lineData.length + ', lineData: ' + lineData)

            // Take most line
            if (lineData.length > currentRowLine) {
                currentRowLine = lineData.length;
            }

            // console.log(alignRight[i])
            // console.log(lineData)

            // If align Right, need to add Width before write data
            if (alignRight[i]) {
                currentX += width[i]
                this.AddAlignRightTextNew(isPOS, data[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color);
            } else {
                this.AddMultiLineText(isPOS, lineData, width[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color);
                currentX += width[i]
            }
        }

        return currentRowLine;
    }

    static InsertTableLineRemark(isPOS: boolean, data: string[], width: number[], alignRight: boolean[], page, font, currentX, currentY, tableLineHeight, dataTextSize, color = cmyk(0, 0, 0, 1)) {
        // Prepare for new line
        // var currentX = xOffset + tableXOffset
        var currentRowLine = 1

        for (var i = 0; i < data.length; i++) {
            let paragraphResult = this.fillParagraph(data[i], font, dataTextSize, width[i]);

            let rowLine = paragraphResult.split('\n').length;
            
            // Take largest row height
            if(rowLine > currentRowLine) {
                currentRowLine = rowLine;
            }

            // If align Right, need to add Width before write data
            if (alignRight[i]) {
                currentX += width[i]
                this.AddAlignRightTextNew(isPOS, data[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color);
            } else {
                this.AddMultiLineText(isPOS, [paragraphResult], width[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color);
                currentX += width[i]
            }
        }

        return currentRowLine;
    }

    static async Invoice(data) {
        const pdfDoc = await PDFDocument.create();

        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

        const xOffset = 40
        const yOffset = 40
        const dataTextSize = 10
        const logoWidth = 60
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const headerTextSize = 24
        const xSpaceBetweenLogoAddress = 20

        var yBillToStart = 120
        var billToMaxWidth = 230;
        const tableXOffset = 20;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 20;
        const tableHeaderTop = 3;
        const tableHeaderBottom = 7;
        const tableLineSpacing = 10;
        var currentRowLine = 1;
        var currentY = height - yBillToStart - dataLineHeight * 5 - spaceBetweenBillToNHeader;
        var currentX = xOffset;

        var columnPercentage = [0.1, 0.5, 0.1, 0.1, 0.2]
        var columnAlign = [false, false, true, true, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderUnitPrice, data.HeaderQty, data.HeaderTotal]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth]

        var bottomReservedSpace = 50;
        var footerSpace = 150;

        // Header START
        if (data.IssuerLogo) {
            const jpgImage = await pdfDoc.embedJpg(data.IssuerLogo)

            page.drawImage(jpgImage, {
                x: xOffset,
                y: height - yOffset - jpgImage.height / 2, // 100 - 10 - 3 - 
                width: jpgImage.width,
                height: jpgImage.height,
                // rotate: degrees(30),
                // opacity: 0.75,
            })

            // https://stackoverflow.com/questions/60262319/regex-javascript-split-string-to-separate-lines-by-max-characters-per-line-wi
            var issuerData = this.TextToLineArray((data.IssuerCompanyName ? data.IssuerCompanyName + "\n" : "") +
                (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : ""));

            this.AddVerticalCenterMultiLineText(issuerData, page, xOffset + xSpaceBetweenLogoAddress + logoWidth, height - yOffset, dataLineHeight, dataTextSize);

        } else {
            // https://stackoverflow.com/questions/60262319/regex-javascript-split-string-to-separate-lines-by-max-characters-per-line-wi
            var issuerData = this.TextToLineArray((data.IssuerCompanyName ? data.IssuerCompanyName + "\n" : "") +
                (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : ""));

            this.AddVerticalCenterMultiLineText(issuerData, page, xOffset, height - yOffset, dataLineHeight, dataTextSize);

        }

        this.AddAlignRightTextNew(false, data.Invoice, page, font, width - xOffset, height - yOffset - 20, headerTextSize, headerTextSize);

        this.AddAlignRightTextNew(false, data.InvoiceNo, page, font, width - xOffset, height - yOffset - 36, dataLineHeight, dataTextSize);

        // Line start at
        page.drawText(`Bill To`, {
            x: xOffset,
            y: height - yBillToStart,
            size: dataTextSize,
            // color: color,
            // font: font
        })

        var billToData = this.TextToLineArray((data.CompanyName ? data.CompanyName + "\n" : "") +
            (data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            (data.State ? data.State + "." : ""));

        this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, xOffset, height - yBillToStart - dataLineHeight, dataLineHeight, dataTextSize);

        this.AddAlignRightTextNew(false, 'Invoice Date: ' + data.InvoiceDate, page, font, width - xOffset, height - yBillToStart, dataLineHeight, dataTextSize);

        this.AddAlignRightTextNew(false, 'Validity: ' + data.Validity, page, font, width - xOffset, height - yBillToStart - dataLineHeight * 2, dataLineHeight, dataTextSize);

        this.AddAlignRightTextNew(false, 'Expiry Date: ' + data.ExpiryDate, page, font, width - xOffset, height - yBillToStart - dataLineHeight * 4, dataLineHeight, dataTextSize);

        // Seperator Line
        page.drawLine({
            start: { x: xOffset, y: currentY },
            end: { x: width - xOffset, y: currentY },
            thickness: 1,
            color: rgb(1, 0.224, 0.224),
            opacity: 0.6,
        })

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize);

        // Seperator Line
        currentY -= tableHeaderBottom

        page.drawLine({
            start: { x: xOffset, y: currentY },
            end: { x: width - xOffset, y: currentY },
            thickness: 1,
            color: rgb(0.224, 0.224, 0.224),
            opacity: 0.6,
        })

        // // Start Data
        if (data.Items) {
            var recNum = 1;
            for (var rec of data.Items) {
                if (!(rec.Desc && rec.UnitPrice && rec.Qty && rec.Total)) {
                    continue;
                }

                currentX = xOffset + tableXOffset
                currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

                // Split to Next Page if exceed reserved place
                if (currentY < bottomReservedSpace) {
                    page = pdfDoc.addPage(PageSizes.A4)
                    currentY = height - yOffset
                }

                currentRowLine = this.InsertTableLine(false, [recNum.toString(), rec.Desc.toString(), this.formatMoney(rec.UnitPrice), this.formatMoney(rec.Qty), this.formatMoney(rec.Total)], colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize)

                recNum++;
            }
        }

        // Grand Total
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        page.drawLine({
            start: { x: xOffset, y: currentY },
            end: { x: width - xOffset, y: currentY },
            thickness: 1,
            color: rgb(1, 0.224, 0.224),
            opacity: 0.6,
        })

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        var rowData = [``, ``, ``, `Subtotal`, this.formatMoney(data.Subtotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = [``, ``, ``, `Rounding`, this.formatMoney(data.Rounding)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = [``, ``, ``, `Grand Total`, this.formatMoney(data.GrandTotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)

        // Signing Location
        // Check if need to open in new page
        currentX = xOffset
        if (currentY < footerSpace) {
            page = pdfDoc.addPage(PageSizes.A4)
        }
        currentY = footerSpace

        let notesRowLine = 0;
        const notesMaxChar = 115;
        const notesMaxWidth = width - 2 * xOffset;

        var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
        this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize);
        currentY -= notesLineHeight;
        if (data.Notes) {
            var recNum = 1;
            for (var rec of data.Notes) {
                currentX = xOffset
                currentY -= (notesLineHeight * notesRowLine);

                // Split to Next Page if exceed reserved place
                if (currentY < bottomReservedSpace) {
                    page = pdfDoc.addPage(PageSizes.A4)
                    currentY = height - yOffset
                }

                multiline = this.TextToLineArray(rec.Info, notesMaxChar)
                this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize);
                notesRowLine = multiline.length

                recNum++;
            }
        }

        // columnPercentage = [0.2, 0.2]
        // columnAlign = [true, true]
        // rowData = [`Subtotal`, `Amount`]
        // var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        // var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth]
        // currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize)


        // Computer generated
        currentX = xOffset

        page.drawText("This is computer generated invoice. No signature required.", {
            x: currentX,
            y: 30,
            maxWidth: 300,
            lineHeight: dataLineHeight,
            size: dataTextSize,
            // color: color,
            // font: font
        })


        const pdfDataUri = await pdfDoc.save()//.saveAsBase64({ dataUri: true });
        return pdfDataUri;
    }

    static calculateRequiredHeightForNotes(notes, maxLength, lineHeight) {
        let heightRequired = 0;

        for (var note of notes) {
            var lineData = this.TextToLineArray(note.Info, maxLength);

            heightRequired += lineData.length * lineHeight
        }

        return heightRequired
    }

    static calculateRequiredHeightForNotesGeneric(note, maxLength, lineHeight) {
        let heightRequired = 0;

        var lineData = this.TextToLineArray(note, maxLength);

        heightRequired += lineData.length * lineHeight

        return heightRequired
    }

    static calculateSingleLineDataHeight(data, maxLength, lineHeight) {
        let heightRequired = 0;

        var lineData = this.TextToLineArray(data, maxLength);
        heightRequired += lineData.length * lineHeight

        return heightRequired
    }

    static prepareTableContent(items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedHeight, yOffset, lowerAreaRequiredHeight, colWidth, columnAlign, font, dataTextSize, fontColor, isStripedRows = true, showCode = true, showUnitName = true, isDelivery = false) {
        let currentRowLine = 0;
        let rowHeight : number[] = [];

        if (items) {
            var recNum = 1;
            for (var rec of items) {
                // Data checking START
                if (!rec.Desc)
                    continue;

                if (!rec.UnitPrice)
                    rec.UnitPrice = 0;

                if (!rec.Qty)
                    rec.Qty = 0;

                if (!rec.Total)
                    rec.Total = 0;

                if (!rec.Code)
                    rec.Code = '';

                if (!rec.UnitName)
                    rec.UnitName = '';

                if (!rec.Remark)
                    rec.Remark = '';
                else
                    rec.Desc = rec.Desc + '\n' + rec.Remark;
                    
                // Data checkin END

                var dataLines = this.TextToLineArray(rec.Desc.toString());

                if (isStripedRows) {
                    if (recNum % 2 != 0) {
                        page.drawRectangle({
                            x: currentX,
                            y: currentY - tableLineHeight * dataLines.length + tableLineSpacing, // 100 - 10 - 3 - 
                            width: tableWidth,
                            height: (dataLines.length * tableLineHeight) + 7,
                            color: cmyk(0.0, 0.0, 0.0, 0.05)
                        })
                    }
                }

                // Split to Next Page if exceed reserved place
                let upcomingLine = this.TextToLineArray(rec.Desc).length * tableLineHeight + tableLineSpacing

                if ((currentY - upcomingLine) < bottomReservedHeight || currentY < bottomReservedHeight) {
                    // console.log('add page in prepareTableContent')

                    page = pdfDoc.addPage(PageSizes.A4)
                    const { width, height } = page.getSize()
                    currentY = height - yOffset
                }

                if (isDelivery) {
                    if (showCode) {
                        currentRowLine = this.InsertTableLineRemark(false, [recNum.toString(), rec.Code, rec.Desc.toString(), showUnitName ? this.formatMoney(rec.Qty) + ' ' + rec.UnitName : this.formatMoney(rec.Qty)], colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)

                        // if(rec.Remark) {
                        //     console.log(1)
                        //     currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        //     currentRowLine = this.InsertTableLineRemark(false, ['', '', rec.Remark], [colWidth[0], colWidth[1], colWidth[2] + colWidth[3]], columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)

                        //     // currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        // }
                    } else {
                        currentRowLine = this.InsertTableLineRemark(false, [recNum.toString(), rec.Desc.toString(), showUnitName ? this.formatMoney(rec.Qty) + ' ' + rec.UnitName : this.formatMoney(rec.Qty)], colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)

                        // if(rec.Remark) {
                        //     console.log(2)
                        //     currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        //     currentRowLine = this.InsertTableLineRemark(false, ['', rec.Remark], [colWidth[0], colWidth[1] + colWidth[2]], columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)
                            
                        //     // currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        // }
                    }
                } else {
                    // console.log(rec)
                    if (showCode) {
                        currentRowLine = this.InsertTableLineRemark(false, [recNum.toString(), rec.Code, rec.Desc.toString(), showUnitName ? this.formatMoney(rec.Qty) + ' ' + rec.UnitName : this.formatMoney(rec.Qty), this.formatMoney(rec.UnitPrice), this.formatMoney(rec.Total)], colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)

                        // if(rec.Remark) {
                        //     console.log(3)
                        //     currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        //     currentRowLine = this.InsertTableLineRemark(false, ['', '', rec.Remark], [colWidth[0], colWidth[1], colWidth[2] + colWidth[3] + colWidth[4] + colWidth[5]], columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)
                            
                        //     // currentY -= (font.heightAtSize(dataTextSize) * currentRowLine + tableLineSpacing);
                        // }
                    } else {
                        currentRowLine = this.InsertTableLineRemark(false, [recNum.toString(), rec.Desc.toString(), showUnitName ? this.formatMoney(rec.Qty) + ' ' + rec.UnitName : this.formatMoney(rec.Qty), this.formatMoney(rec.UnitPrice), this.formatMoney(rec.Total)], colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)

                        // if(rec.Remark) {
                        //     console.log(4)
                        //     currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        //     currentRowLine = this.InsertTableLineRemark(false, ['', rec.Remark], [colWidth[0], colWidth[1], colWidth[2] + colWidth[3] + colWidth[4]], columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize, fontColor)
                            
                        //     // currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                        // }
                    }
                }

                // console.log(tableLineHeight)
                // console.log(currentRowLine)
                // console.log(tableLineHeight * currentRowLine)
                // console.log(tableLineHeight * currentRowLine + tableLineSpacing)
                
                rowHeight.push((tableLineHeight * currentRowLine + tableLineSpacing));
                // console.log(rowHeight)
                currentY -= rowHeight[rowHeight.length - 1];

                recNum++;
            }

            // if (lowerAreaRequiredHeight) {
            do {
                if (isStripedRows) {
                    if (recNum % 2 != 0) {
                        page.drawRectangle({
                            x: currentX,
                            y: currentY - 5, // 100 - 10 - 3 - 
                            width: tableWidth,
                            height: tableLineHeight + 7,
                            color: cmyk(0.0, 0.0, 0.0, 0.05)// rgb(0.64, 0.58, 0.36),
                            // opacity: 0.6,
                            // rotate: degrees(30),
                            // opacity: 0.75,
                        })
                    }
                }

                recNum++;
                currentY -= (tableLineHeight + tableLineSpacing);   // Single Line spacing
            } while (currentY - tableLineHeight - tableLineSpacing > lowerAreaRequiredHeight)
            // }
        }

        return currentY;
    }

    static drawIssuerContent(page, currentX, currentY, name, regNo, address, headerFont, headerTextSize, font, textSize, textColor): { x: number; y: number } {
        const { width, height } = page.getSize()

        const heightBtwnCompanyNameNAddress = 5;
        const notesMaxChar = 105;
        const spacingBetweenNameNRegNo = 10;

        const oriX = currentX;
        const heightBtwnCompanyAddressNInvoice = 35;

        const lineSpacing = 1.5;

        let regNoServedSpace = 0

        if (regNo) {
            regNoServedSpace = font.widthOfTextAtSize(regNo, textSize);
        }

        // const dataWidth = width - 2 * oriX
        const dataWidth = width - oriX

        this.AddMultiLineText(false, name, dataWidth - regNoServedSpace - spacingBetweenNameNRegNo, page, headerFont, currentX, currentY, headerTextSize, headerTextSize);

        if (regNo) {
            currentX += headerFont.widthOfTextAtSize(name[0], headerTextSize) + spacingBetweenNameNRegNo;
            this.AddMultiLineText(false, ['(' + regNo + ')'], width - currentX - oriX, page, font, currentX, currentY, textSize * lineSpacing, textSize, textColor);

            // Reset X axis
            currentX = oriX;
        }

        // Shift to Address row
        currentY -= (heightBtwnCompanyNameNAddress + headerTextSize)

        var issuerData = this.TextToLineArray(address, notesMaxChar);
        this.AddMultiLineText(false, issuerData, dataWidth, page, font, currentX, currentY, textSize * lineSpacing, textSize, textColor);

        currentY -= (heightBtwnCompanyAddressNInvoice + textSize * lineSpacing * issuerData.length)

        return { x: currentX, y: currentY }
    }

    static InsertTableWData(header: string[], data: string[], width: number[], alignRight: boolean[], page, currentX, currentY, tableLineHeight, fontBold, dataTextSize, font, color, headerColor = cmyk(0, 0, 0, 1)) {
        // Prepare for new line
        // var currentX = xOffset + tableXOffset
        var currentRowLine = 1
        const oriX = currentX;

        for (var i = 0; i < header.length; i++) {
            var lineData = this.TextToLineArray(header[i]);

            // Take most line
            if (lineData.length > currentRowLine) {
                currentRowLine = lineData.length;
            }

            // If align Right, need to add Width before write data
            if (alignRight[i]) {
                currentX += width[i]
                this.AddAlignRightTextNew(false, header[i], page, fontBold, currentX, currentY, tableLineHeight, dataTextSize, headerColor)
            } else {
                this.AddMultiLineText(false, lineData, width[i], page, fontBold, currentX, currentY, tableLineHeight, dataTextSize, headerColor);
                currentX += width[i]
            }
        }

        currentX = oriX;
        currentY -= currentRowLine * tableLineHeight;

        currentRowLine = 0;
        for (var i = 0; i < data.length; i++) {
            var lineData = this.TextToLineArray(data[i]);

            // Take most line
            if (lineData.length > currentRowLine) {
                currentRowLine = lineData.length;
            }

            // If align Right, need to add Width before write data
            if (alignRight[i]) {
                currentX += width[i]
                this.AddAlignRightTextNew(false, data[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color)
            } else {
                this.AddMultiLineText(false, lineData, width[i], page, font, currentX, currentY, tableLineHeight, dataTextSize, color);
                currentX += width[i]
            }
        }

        currentX = oriX;
        currentY -= currentRowLine * tableLineHeight;

        return currentY;
    }

    static InsertTableWDataVertical(header: string[], data: string[], page, currentX, currentY, tableLineHeight, fontBold, dataTextSize, font, color, headerColor = cmyk(0, 0, 0, 1)) {
        var currentRowLine = 1
        var headerWidth = 1;
        const oriX = currentX;

        // Calculate Max Width required for Header fields
        for (var i = 0; i < header.length; i++) {
            var textWidth = font.widthOfTextAtSize(header[i], dataTextSize);

            if (textWidth > headerWidth) {
                headerWidth = textWidth;
            }
        }

        for (var i = 0; i < header.length; i++) {
            var lineHeader = this.TextToLineArray(header[i]);

            // Take most line
            if (lineHeader.length > currentRowLine) {
                currentRowLine = lineHeader.length;
            }

            var lineData = this.TextToLineArray(data[i]);

            // Take most line
            if (lineData.length > currentRowLine) {
                currentRowLine = lineData.length;
            }

            // If align Right, need to add Width before write data
            currentX += headerWidth
            this.AddAlignRightTextNew(false, header[i], page, fontBold, currentX, currentY, tableLineHeight, dataTextSize, headerColor)
            currentX += 10
            this.AddMultiLineText(false, lineData, oriX - headerWidth - 10, page, font, currentX, currentY, tableLineHeight, dataTextSize, color);

            currentX = oriX;
            currentY -= currentRowLine * tableLineHeight;
        }

        return currentY;
    }

    static async InvoiceGeneric(printData, isBase64 = false) {
        let data = JSON.parse(JSON.stringify(printData));

        // console.log(data);
        
        // Create PDF document
        const pdfDoc = await PDFDocument.create();

        // Add new page to PDF
        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        // Define settings
        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.0, 0.88, 0.41, 0.12);
        const fontInvoiceColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.73, 0.64, 0.00, 0.43);

        const xOffset = data.XOffset
        const yOffset = data.YOffset
        const dataTextSize = 9
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 20

        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentX = xOffset;

        const heightBtwnInvoiceNDateInvoice = 15;

        // Calculate Table Column width
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var columnPercentage = [0.07, 0.13, 0.4, 0.1, 0.15, 0.15]
        var columnAlign = [false, false, false, true, true, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderCode, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth, columnPercentage[5] * tableWidth]

        // Recalculate Table Column width if Hide Code
        if (data.ShowCode === false) {
            columnPercentage = [0.07, 0.53, 0.1, 0.15, 0.15]
            columnAlign = [false, false, true, true, true]
            rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
            colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth]
        }

        // Page Y starting
        var currentY = height - yOffset - 14;

        // Header START
        if (data.ShowCompany === true) {
            // Draw Issuer Name, RegNo, Address
            var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : "") +
                (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

            let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

            currentX = xOffset;
            currentY = y;
        }

        // Draw Invoice Title
        this.AddHorizontalCenterMultiLineText([data.Invoice], page, xOffset, currentY, headerTextSize, headerTextSize, boldFont, width, fontInvoiceColor);
        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)

        const notesMaxChar = 105;
        const heightBtwnDateInvoiceNLine = 10;

        // Draw QR Code
        if(data.ShowQRCode) {
            let qrCode = await this.getBarcodeData(environment.webURL + '/tx/' + data.InvoiceNo.substring(0, 2) + '/' + data.ID, "").toDataURL("image/jpeg");
            const jpgImage = await pdfDoc.embedJpg(qrCode);
            page.drawImage(jpgImage, {
                x: width - xOffset - 50,
                y: currentY - 20,
                width: 50,
                height: 50,
                // rotate: degrees(30),
                // opacity: 0.75,
            })

            page.drawText(data.InvoiceNo.slice(data.InvoiceNo.length - 6), {
                x: width - xOffset - 42,
                y: currentY - 27,
                size: 10,
                // rotate: degrees(30),
                // opacity: 0.75,
            })
        }

        if (data.TopDown) {
            // Draw Invoice Info
            var iiColumnPercentage = [0.3, 0.3, 0.4]
            var iiColumnAlign = [false, false, false]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth, iiColumnPercentage[2] * tableWidth]
            currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentY -= heightBtwnDateInvoiceNLine

            // Draw Line
            currentX -= 2
            this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // page.drawLine({
            //     start: { x: currentX - 2, y: currentY },
            //     end: { x: width - xOffset + 4, y: currentY },
            //     thickness: 1,
            //     color: fontColor
            // })

            // Line start at
            currentX = xOffset
            currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
            this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })
        } else {
            // Line start at
            let startCurrentY = currentY;

            currentX = xOffset
            // currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth / 2, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            // var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            //     (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            //     (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            //     (data.State ? data.State + "." : "") +
            //     (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
                
             var billToDataRaw = (data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : "");

            let paragraphResult = this.fillParagraph(billToDataRaw, font, dataTextSize, billToMaxWidth / 2);

            let rowLine = paragraphResult.split('\n').length;

            this.AddMultiLineText(false, [paragraphResult], billToMaxWidth / 2, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * rowLine)
            // page.drawLine({
            //     start: { x: currentX - 2, y: currentY },
            //     end: { x: width - xOffset + 4, y: currentY },
            //     thickness: 1,
            //     color: fontColor
            // })

            let endY = currentY;

            currentY = startCurrentY;

            // Draw Invoice Info
            // var iiColumnPercentage = [0.3, 0.7]
            // var iiColumnAlign = [true, true]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            // var iiColWidth = [iiColumnPercentage[0] * billToMaxWidth / 2, iiColumnPercentage[1] * billToMaxWidth / 2]
            currentX += billToMaxWidth / 2;

            currentY = this.InsertTableWDataVertical(iiRowDataHeader, iiRowData, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentX = xOffset

            currentY -= heightBtwnDateInvoiceNLine

            if(endY < currentY) {
                currentY = endY;
            }

            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })

            // // Draw Line
            // currentX -= 2
            // this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // // page.drawLine({
            // //     start: { x: currentX - 2, y: currentY },
            // //     end: { x: width - xOffset + 4, y: currentY },
            // //     thickness: 1,
            // //     color: fontColor
            // // })


        }
        
        var bottomReservedSpace = !data.BottomReserved ? 50 : data.BottomReserved;

        let notesRowLine = 0;
        const notesMaxWidth = width - 2 * xOffset;

        const declaration = data.Declaration;

        // Calculate required Footer size
        let spacingBetweenPageBottom = 40;
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotesGeneric(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight(declaration, notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom + totalHeightRequiredHeight

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor, data.IsStripedRow, data.ShowCode, data.ShowUnitName);

        // Grand Total
        // Get last page
        page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);

        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            // console.log('add page in invoiceGeneric')

            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        var rowData = data.ShowCode ? [``, ``, ``, ``, data.HeaderSubtotal, this.formatMoney(data.Subtotal)] : [``, ``, ``, data.HeaderSubtotal, this.formatMoney(data.Subtotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = data.ShowCode ? [``, ``, ``, ``, data.HeaderRounding, this.formatMoney(data.Rounding)] : [``, ``, ``, data.HeaderRounding, this.formatMoney(data.Rounding)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = width - xOffset;
        currentY -= (companyHeaderTextSize + tableLineHeight + tableHeaderTop)
        this.AddAlignRightTextNew(false, 'RM ' + this.formatMoney(data.GrandTotal), page, boldFont, currentX, currentY, companyHeaderTextSize, companyHeaderTextSize, fontTotalColor);

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + spacingBetweenPageBottom) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        if (data.HeaderNotes) {
            var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            currentY -= notesLineHeight;
        }

        currentX = xOffset
        if (data.Notes) {
            // var recNum = 1;
            // for (var rec of data.Notes) {
            multiline = this.TextToLineArray(data.Notes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            notesRowLine = multiline.length

            currentY -= (notesLineHeight * notesRowLine);

            // recNum++;
            // }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, [declaration], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        if (data.ShowFooter === true) {
            this.addPageNumFooter(pdfDoc);
        }

        // Draw page background
        if (data.Attachments && data.Attachments.length > 0) {
            const bgBytes = await fetch(data.Attachments[0].AttachmentPath + '?CID=' + data.CompanyID).then(res => res.arrayBuffer())

            const background = await pdfDoc.embedPng(bgBytes);

            for (var pg of pdfDoc.getPages()) {
                pg.drawImage(background, {
                    x: 0,
                    y: 0,
                    width: width,
                    height: height,
                })
            }
        }

        // if(data.background) {
        //     console.log('draw background')
        //     const background = await pdfDoc.embedPng(data.background);

        //     for(var pg of pdfDoc.getPages()) {
        //         pg.drawImage(background, {
        //             x: 0,
        //             y: 0,
        //             width: width,
        //             height: height,
        //         })
        //     }
        // }

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }

    static async InvoiceDotMatrixGeneric(data, isBase64 = false) {
        // console.log(data);
        
        // Create PDF document
        const pdfDoc = await PDFDocument.create();

        // Add new page to PDF
        var page = pdfDoc.addPage(PageSizes.Letter) // [550, 750]

        const url = '/assets/fonts/tahoma.ttf'
        const urlBold = '/assets/fonts/tahomabd.ttf'
        const fontBytes = await fetch(url).then(res => res.arrayBuffer())
        const fontBoldBytes = await fetch(urlBold).then(res => res.arrayBuffer())

        pdfDoc.registerFontkit(fontkit)
        const font = await pdfDoc.embedFont(fontBytes);
        const boldFont = await pdfDoc.embedFont(fontBoldBytes);

        // Define settings
        const { width, height } = page.getSize()
        // const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        // const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = cmyk(0.0, 0.0, 0.0, 1);
        const fontTotalColor = cmyk(0.0, 0.0, 0.0, 1);
        const fontInvoiceColor = cmyk(0.0, 0.0, 0.0, 1);

        const xOffset = data.XOffset
        const yOffset = data.YOffset
        const dataTextSize = 9
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 20

        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentX = xOffset;

        const heightBtwnInvoiceNDateInvoice = 15;

        // Calculate Table Column width
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var columnPercentage = [0.07, 0.13, 0.4, 0.1, 0.15, 0.15]
        var columnAlign = [false, false, false, true, true, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderCode, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth, columnPercentage[5] * tableWidth]

        // Recalculate Table Column width if Hide Code
        if (data.ShowCode === false) {
            columnPercentage = [0.07, 0.53, 0.1, 0.15, 0.15]
            columnAlign = [false, false, true, true, true]
            rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
            colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth]
        }

        // Page Y starting
        var currentY = height - yOffset - 14;

        // Header START
        if (data.ShowCompany === true) {
            // Draw Issuer Name, RegNo, Address
            var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : "") +
                (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

            let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

            currentX = xOffset;
            currentY = y;
        }

        // Draw Invoice Title
        this.AddHorizontalCenterMultiLineText([data.Invoice], page, xOffset, currentY, headerTextSize, headerTextSize, boldFont, width, fontInvoiceColor);
        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)

        const notesMaxChar = 105;
        const heightBtwnDateInvoiceNLine = 10;

        // Draw QR Code
        if(data.ShowQRCode) {
            let qrCode = await this.getBarcodeData(environment.webURL + '/tx/' + data.InvoiceNo.substring(0, 2) + '/' + data.ID, "").toDataURL("image/jpeg");
            const jpgImage = await pdfDoc.embedJpg(qrCode);
            page.drawImage(jpgImage, {
                x: width - xOffset - 50,
                y: currentY - 20,
                width: 50,
                height: 50,
                // rotate: degrees(30),
                // opacity: 0.75,
            })

            page.drawText(data.InvoiceNo.slice(data.InvoiceNo.length - 6), {
                x: width - xOffset - 42,
                y: currentY - 27,
                size: 10,
                // rotate: degrees(30),
                // opacity: 0.75,
            })
    }

        if (data.TopDown) {
            // Draw Invoice Info
            var iiColumnPercentage = [0.3, 0.3, 0.4]
            var iiColumnAlign = [false, false, false]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth, iiColumnPercentage[2] * tableWidth]
            currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentY -= heightBtwnDateInvoiceNLine

            // Draw Line
            currentX -= 2
            this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // page.drawLine({
            //     start: { x: currentX - 2, y: currentY },
            //     end: { x: width - xOffset + 4, y: currentY },
            //     thickness: 1,
            //     color: fontColor
            // })

            // Line start at
            currentX = xOffset
            currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
            this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })
        } else {
            // Line start at
            let startCurrentY = currentY;

            currentX = xOffset
            // currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth / 2, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            // var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            //     (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            //     (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            //     (data.State ? data.State + "." : "") +
            //     (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
                
             var billToDataRaw = (data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : "");

            let paragraphResult = this.fillParagraph(billToDataRaw, font, dataTextSize, billToMaxWidth / 2);

            let rowLine = paragraphResult.split('\n').length;

            this.AddMultiLineText(false, [paragraphResult], billToMaxWidth / 2, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * rowLine)
            // page.drawLine({
            //     start: { x: currentX - 2, y: currentY },
            //     end: { x: width - xOffset + 4, y: currentY },
            //     thickness: 1,
            //     color: fontColor
            // })

            let endY = currentY;

            currentY = startCurrentY;

            // Draw Invoice Info
            // var iiColumnPercentage = [0.3, 0.7]
            // var iiColumnAlign = [true, true]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            // var iiColWidth = [iiColumnPercentage[0] * billToMaxWidth / 2, iiColumnPercentage[1] * billToMaxWidth / 2]
            currentX += billToMaxWidth / 2;

            currentY = this.InsertTableWDataVertical(iiRowDataHeader, iiRowData, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentX = xOffset

            currentY -= heightBtwnDateInvoiceNLine

            if(endY < currentY) {
                currentY = endY;
            }

            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })

            // // Draw Line
            // currentX -= 2
            // this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // // page.drawLine({
            // //     start: { x: currentX - 2, y: currentY },
            // //     end: { x: width - xOffset + 4, y: currentY },
            // //     thickness: 1,
            // //     color: fontColor
            // // })


        }

        var bottomReservedSpace = 50;

        let notesRowLine = 0;
        const notesMaxWidth = width - 2 * xOffset;

        const declaration = data.Declaration;

        // Calculate required Footer size
        let spacingBetweenPageBottom = 40;
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotesGeneric(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight(declaration, notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom + totalHeightRequiredHeight

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor, data.IsStripedRow, data.ShowCode, data.ShowUnitName);

        // Grand Total
        // Get last page
        page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);

        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            // console.log('add page in invoiceGeneric')

            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        var rowData = data.ShowCode ? [``, ``, ``, ``, data.HeaderSubtotal, this.formatMoney(data.Subtotal)] : [``, ``, ``, data.HeaderSubtotal, this.formatMoney(data.Subtotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = data.ShowCode ? [``, ``, ``, ``, data.HeaderRounding, this.formatMoney(data.Rounding)] : [``, ``, ``, data.HeaderRounding, this.formatMoney(data.Rounding)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = width - xOffset;
        currentY -= (companyHeaderTextSize + tableLineHeight + tableHeaderTop)
        this.AddAlignRightTextNew(false, 'RM ' + this.formatMoney(data.GrandTotal), page, boldFont, currentX, currentY, companyHeaderTextSize, companyHeaderTextSize, fontTotalColor);

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + spacingBetweenPageBottom) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        if (data.HeaderNotes) {
            var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            currentY -= notesLineHeight;
        }

        currentX = xOffset
        if (data.Notes) {
            // var recNum = 1;
            // for (var rec of data.Notes) {
            multiline = this.TextToLineArray(data.Notes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            notesRowLine = multiline.length

            currentY -= (notesLineHeight * notesRowLine);

            // recNum++;
            // }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, [declaration], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        if (data.ShowFooter === true) {
            this.addPageNumFooter(pdfDoc);
        }

        // Draw page background
        if (data.Attachments && data.Attachments.length > 0) {
            const bgBytes = await fetch(data.Attachments[0].AttachmentPath + '?CID=' + data.CompanyID).then(res => res.arrayBuffer())

            const background = await pdfDoc.embedPng(bgBytes);

            for (var pg of pdfDoc.getPages()) {
                pg.drawImage(background, {
                    x: 0,
                    y: 0,
                    width: width,
                    height: height,
                })
            }
        }

        // if(data.background) {
        //     console.log('draw background')
        //     const background = await pdfDoc.embedPng(data.background);

        //     for(var pg of pdfDoc.getPages()) {
        //         pg.drawImage(background, {
        //             x: 0,
        //             y: 0,
        //             width: width,
        //             height: height,
        //         })
        //     }
        // }

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }
    
    static async DeliveryGeneric(printData, isBase64 = false) {
        let data = JSON.parse(JSON.stringify(printData));

        // Create PDF document
        const pdfDoc = await PDFDocument.create();

        // Add new page to PDF
        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        // Define settings
        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.0, 0.88, 0.41, 0.12);
        const fontInvoiceColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.73, 0.64, 0.00, 0.43);

        const xOffset = data.XOffset
        const yOffset = data.YOffset
        const dataTextSize = 9
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 20

        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentX = xOffset;

        const heightBtwnInvoiceNDateInvoice = 15;

        // Calculate Table Column width
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var columnPercentage = [0.1, 0.13, 0.67, 0.1]
        var columnAlign = [false, false, false, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderCode, data.HeaderDesc, data.HeaderQty]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth]

        // Recalculate Table Column width if Hide Code
        if (data.ShowCode === false) {
            var columnPercentage = [0.1, 0.8, 0.1]
            var columnAlign = [false, false, false, true]
            var rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty]
            var tableWidth = width - 2 * xOffset - 2 * tableXOffset
            var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth]
        }

        // Page Y starting
        var currentY = height - yOffset - 14;

        // Header START
        if (data.ShowCompany === true) {
            // Draw Issuer Name, RegNo, Address
            var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : "") +
                (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

            let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

            currentX = xOffset;
            currentY = y;
        }

        // Draw Invoice Title
        this.AddHorizontalCenterMultiLineText([data.Invoice], page, xOffset, currentY, headerTextSize, headerTextSize, boldFont, width, fontInvoiceColor);
        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)

        const notesMaxChar = 105;
        const heightBtwnDateInvoiceNLine = 10;

        // Draw QR Code
        if(data.ShowQRCode) {
            let qrCode = await this.getBarcodeData(environment.webURL + '/tx/' + data.InvoiceNo.substring(0, 2) + '/' + data.ID, "").toDataURL("image/jpeg");
            const jpgImage = await pdfDoc.embedJpg(qrCode);
            page.drawImage(jpgImage, {
                x: width - xOffset - 50,
                y: currentY - 20,
                width: 50,
                height: 50,
                // rotate: degrees(30),
                // opacity: 0.75,
            })

            page.drawText(data.InvoiceNo.slice(data.InvoiceNo.length - 6), {
                x: width - xOffset - 42,
                y: currentY - 27,
                size: 10,
                // rotate: degrees(30),
                // opacity: 0.75,
            })
        }

        if (data.TopDown) {
            // Draw Invoice Info
            var iiColumnPercentage = [0.3, 0.3, 0.4]
            var iiColumnAlign = [false, false, false]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth, iiColumnPercentage[2] * tableWidth]
            currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentY -= heightBtwnDateInvoiceNLine

            // Draw Line
            currentX -= 2
            this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // page.drawLine({
            //     start: { x: currentX - 2, y: currentY },
            //     end: { x: width - xOffset + 4, y: currentY },
            //     thickness: 1,
            //     color: fontColor
            // })

            // Line start at
            currentX = xOffset
            currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
            this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })
        } else {
            // Line start at
            let startCurrentY = currentY;

            currentX = xOffset
            // currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth / 2, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
            this.AddMultiLineText(false, billToData, billToMaxWidth / 2, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })

            currentY = startCurrentY;

            // Draw Invoice Info
            // var iiColumnPercentage = [0.3, 0.7]
            // var iiColumnAlign = [true, true]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            // var iiColWidth = [iiColumnPercentage[0] * billToMaxWidth / 2, iiColumnPercentage[1] * billToMaxWidth / 2]
            currentX += billToMaxWidth / 2;

            currentY = this.InsertTableWDataVertical(iiRowDataHeader, iiRowData, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentY -= heightBtwnDateInvoiceNLine

            currentX = xOffset

            // // Draw Line
            // currentX -= 2
            // this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // // page.drawLine({
            // //     start: { x: currentX - 2, y: currentY },
            // //     end: { x: width - xOffset + 4, y: currentY },
            // //     thickness: 1,
            // //     color: fontColor
            // // })


        }

        var bottomReservedSpace = 50;

        let notesRowLine = 0;
        const notesMaxWidth = width - 2 * xOffset;

        const declaration = data.Declaration;

        // Calculate required Footer size
        let spacingBetweenPageBottom = 40;
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotesGeneric(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight(declaration, notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom + totalHeightRequiredHeight

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor, data.IsStripedRow, data.ShowCode, data.ShowUnitName, true);

        // Grand Total
        // Get last page
        page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);

        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            // console.log('add page in invoiceGeneric')

            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + spacingBetweenPageBottom) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        if (data.HeaderNotes) {
            var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            currentY -= notesLineHeight;
        }

        currentX = xOffset
        if (data.Notes) {
            // var recNum = 1;
            // for (var rec of data.Notes) {
            multiline = this.TextToLineArray(data.Notes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            notesRowLine = multiline.length

            currentY -= (notesLineHeight * notesRowLine);

            // recNum++;
            // }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, [declaration], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        if (data.ShowFooter === true) {
            this.addPageNumFooter(pdfDoc);
        }

        // Draw page background
        if (data.Attachments && data.Attachments.length > 0) {
            const bgBytes = await fetch(data.Attachments[0].AttachmentPath + '?CID=' + data.CompanyID).then(res => res.arrayBuffer())

            const background = await pdfDoc.embedPng(bgBytes);

            for (var pg of pdfDoc.getPages()) {
                pg.drawImage(background, {
                    x: 0,
                    y: 0,
                    width: width,
                    height: height,
                })
            }
        }

        // if(data.background) {
        //     console.log('draw background')
        //     const background = await pdfDoc.embedPng(data.background);

        //     for(var pg of pdfDoc.getPages()) {
        //         pg.drawImage(background, {
        //             x: 0,
        //             y: 0,
        //             width: width,
        //             height: height,
        //         })
        //     }
        // }

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }

    static async InvoiceNDS(data, isBase64 = false) {
        const pdfDoc = await PDFDocument.create();

        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = cmyk(0.0, 0.88, 0.41, 0.12);
        const fontInvoiceColor = cmyk(0.73, 0.64, 0.00, 0.43);

        const headerLineXOffset = 40
        const xOffset = 60
        const yOffset = 40
        const dataTextSize = 10
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 24

        const yBillToStart = 120
        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableHeaderBottom = 7;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentY = height - yBillToStart - dataLineHeight * 5 - spaceBetweenBillToNHeader;
        var currentX = xOffset;

        var columnPercentage = [0.1, 0.5, 0.1, 0.15, 0.15]
        var columnAlign = [false, false, true, true, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth]

        var bottomReservedSpace = 50;

        currentY = height - 10;

        let notesRowLine = 0;
        const notesMaxChar = 105;
        const notesMaxWidth = width - 2 * xOffset;
        const heightBtwnInvoiceNDateInvoice = 15;
        const heightBtwnDateInvoiceNLine = 10;
        const heightBtwnTopBarNCompanyName = 35;

        // Calculate required Footer size
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotes(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight('This invoice is computer generated, no signature is requried.', notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + yOffset + totalHeightRequiredHeight

        // Header START
        // Draw Header Line
        this.drawLine(page, headerLineXOffset, currentY, width - 2 * headerLineXOffset, 5, fontInvoiceColor);
        currentY -= heightBtwnTopBarNCompanyName

        // Draw Issuer Name, RegNo, Address
        var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
            (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
            (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
            (data.IssuerState ? data.IssuerState + "." : "") +
            (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

        let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

        currentX = xOffset;
        currentY = y;

        // Draw Invoice Title
        this.AddMultiLineText(false, [data.Invoice], billToMaxWidth, page, boldFont, currentX, currentY, headerTextSize, headerTextSize, fontInvoiceColor);
        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)

        // Draw Invoice Info
        var iiColumnPercentage = [0.3, 0.7]
        var iiColumnAlign = [false, false]
        var iiRowDataHeader = ['Date', 'Invoice']
        var iiRowData = [data.InvoiceDate + '', data.InvoiceNo]
        var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth]
        currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

        currentY -= heightBtwnDateInvoiceNLine

        // Draw Line
        currentX -= 2
        this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
        // page.drawLine({
        //     start: { x: currentX - 2, y: currentY },
        //     end: { x: width - xOffset + 4, y: currentY },
        //     thickness: 1,
        //     color: fontColor
        // })

        // Line start at
        currentX = xOffset
        currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
        this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight + dataLineHeight / 2)
        var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            (data.State ? data.State + "." : "") +
            (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
        this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
        page.drawLine({
            start: { x: currentX - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor);

        // Grand Total
        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        var rowData = [``, ``, ``, `Subtotal`, this.formatMoney(data.Subtotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = [``, ``, ``, `Rounding`, this.formatMoney(data.Rounding)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = width - xOffset;
        currentY -= (companyHeaderTextSize + tableLineHeight + tableHeaderTop)
        this.AddAlignRightTextNew(false, 'RM ' + this.formatMoney(data.GrandTotal), page, boldFont, currentX, currentY, companyHeaderTextSize, companyHeaderTextSize, fontTotalColor);

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + yOffset) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + yOffset;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
        this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
        currentY -= notesLineHeight;

        currentX = xOffset
        if (data.Notes) {
            var recNum = 1;
            for (var rec of data.Notes) {
                multiline = this.TextToLineArray(rec.Info, notesMaxChar)
                this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
                notesRowLine = multiline.length

                currentY -= (notesLineHeight * notesRowLine);

                recNum++;
            }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, ["This is computer generated invoice. No signature required."], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        this.addPageNumFooter(pdfDoc);

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }

    static async InvoicePRX(data, isBase64 = false) {
        const pdfDoc = await PDFDocument.create();

        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = cmyk(0.0, 0.88, 0.41, 0.12);
        const fontInvoiceColor = cmyk(0.73, 0.64, 0.00, 0.43);

        const headerLineXOffset = 40
        const xOffset = 60
        const yOffset = 40
        const logoNCompany = 15
        const dataTextSize = 10
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 24

        const yBillToStart = 120
        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableHeaderBottom = 7;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentY = height - yBillToStart - dataLineHeight * 5 - spaceBetweenBillToNHeader;
        var currentX = xOffset;

        var columnPercentage = [0.1, 0.5, 0.1, 0.15, 0.15]
        var columnAlign = [false, false, true, true, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth]

        var bottomReservedSpace = 50;

        currentY = height - 10;

        let notesRowLine = 0;
        const notesMaxChar = 105;
        const notesMaxWidth = width - 2 * xOffset;
        const heightBtwnInvoiceNDateInvoice = 5;
        const heightBtwnDateInvoiceNLine = 10;
        const heightBtwnTopBarNCompanyName = 35;

        // Calculate required Footer size
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotes(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight('This invoice is computer generated, no signature is requried.', notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + yOffset + totalHeightRequiredHeight

        // Header START
        currentY -= heightBtwnTopBarNCompanyName

        // Draw Logo
        if (data.IssuerLogo) {
            const jpgImage = await pdfDoc.embedJpg(data.IssuerLogo)

            currentX += jpgImage.width + logoNCompany;

            // Draw Issuer Name, RegNo, Address
            var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : "") +
                (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

            let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

            currentX = xOffset;
            let dataCenterPoint = currentY - (currentY - y) / 2;

            currentY = dataCenterPoint - (jpgImage.height / 2) + 35;//heightBtwnCompanyAddressNInvoice;

            page.drawImage(jpgImage, {
                x: currentX,
                y: currentY,
                width: jpgImage.width,
                height: jpgImage.height,
                // rotate: degrees(30),
                // opacity: 0.75,
            })

            currentX = xOffset;
            currentY = y;

        } else {
            // Draw Issuer Name, RegNo, Address
            var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : "") +
                (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

            let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

            currentX = xOffset;
            currentY = y;
        }

        // Draw Invoice Title
        let titleWidth = font.widthOfTextAtSize(data.Invoice, headerTextSize);
        currentX = (width - titleWidth) / 2
        this.AddMultiLineText(false, [data.Invoice], billToMaxWidth, page, boldFont, currentX, currentY, headerTextSize, headerTextSize, fontInvoiceColor);

        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)
        currentX = xOffset;

        // Draw Invoice Info
        let oriY = currentY;

        // var iiColumnPercentage = [0.3, 0.7]
        // var iiColumnAlign = [false, false]
        // var iiRowDataHeader = ['Date', 'Invoice']
        // var iiRowData = [data.InvoiceDate + '', data.InvoiceNo]
        // var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth]
        // this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

        currentY = this.AddAlignRightTextNew(false, data.InvoiceDate, page, font, width - xOffset, currentY, dataTextSize, dataTextSize, fontColor);
        currentY -= dataTextSize;
        currentY = this.AddAlignRightTextNew(false, data.InvoiceNo, page, font, width - xOffset, currentY, dataTextSize, dataTextSize, fontColor);

        currentY = oriY;

        this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight + dataLineHeight / 2)
        var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            (data.State ? data.State + "." : "") +
            (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
        this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
        page.drawLine({
            start: { x: currentX - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor);

        // Grand Total
        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        var rowData = [``, ``, ``, `Subtotal`, this.formatMoney(data.Subtotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = [``, ``, ``, `Rounding`, this.formatMoney(data.Rounding)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = width - xOffset;
        currentY -= (companyHeaderTextSize + tableLineHeight + tableHeaderTop)
        this.AddAlignRightTextNew(false, 'RM ' + this.formatMoney(data.GrandTotal), page, boldFont, currentX, currentY, companyHeaderTextSize, companyHeaderTextSize, fontTotalColor);

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + yOffset) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + yOffset;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
        this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
        currentY -= notesLineHeight;

        currentX = xOffset
        if (data.Notes) {
            var recNum = 1;
            for (var rec of data.Notes) {
                multiline = this.TextToLineArray(rec.Info, notesMaxChar)
                this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
                notesRowLine = multiline.length

                currentY -= (notesLineHeight * notesRowLine);

                recNum++;
            }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, ["This is computer generated invoice. No signature required."], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        this.addPageNumFooter(pdfDoc);

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }

    static async QuotationNDS(data, isBase64 = false) {
        const pdfDoc = await PDFDocument.create();

        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = cmyk(0.0, 0.88, 0.41, 0.12);
        const fontQuotationColor = cmyk(0.73, 0.64, 0.00, 0.43);

        const headerLineXOffset = 40
        const xOffset = 60
        const yOffset = 40
        const dataTextSize = 10
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 24

        const yBillToStart = 120
        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableHeaderBottom = 7;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentY = height - yBillToStart - dataLineHeight * 5 - spaceBetweenBillToNHeader;
        var currentX = xOffset;

        var columnPercentage = [0.1, 0.5, 0.1, 0.15, 0.15]
        var columnAlign = [false, false, true, true, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty, data.HeaderUnitPrice, data.HeaderTotal]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth, columnPercentage[4] * tableWidth]

        var bottomReservedSpace = 50;

        currentY = height - 10;

        let notesRowLine = 0;
        const notesMaxChar = 105;
        const notesMaxWidth = width - 2 * xOffset;
        const heightBtwnQuotationNDateQuotation = 15;
        const heightBtwnDateQuotationNLine = 10;
        const heightBtwnTopBarNCompanyName = 35;

        // Calculate required Footer size
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotes(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight('This quotation is computer generated, no signature is requried.', notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + yOffset + totalHeightRequiredHeight

        // Header START
        // Draw Header Line
        this.drawLine(page, headerLineXOffset, currentY, width - 2 * headerLineXOffset, 5, fontQuotationColor);
        currentY -= heightBtwnTopBarNCompanyName

        // Draw Issuer Name, RegNo, Address
        var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
            (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
            (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
            (data.IssuerState ? data.IssuerState + "." : "") +
            (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

        let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

        currentX = xOffset;
        currentY = y;

        // Draw Quotation Title
        this.AddMultiLineText(false, [data.Quotation], billToMaxWidth, page, boldFont, currentX, currentY, headerTextSize, headerTextSize, fontQuotationColor);
        currentY -= (heightBtwnQuotationNDateQuotation + headerTextSize)

        // Draw Quotation Info
        var iiColumnPercentage = [0.3, 0.7]
        var iiColumnAlign = [false, false]
        var iiRowDataHeader = ['Date', 'Quotation']
        var iiRowData = [data.QuotationDate + '', data.QuotationNo]
        var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth]
        currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontQuotationColor);

        currentY -= heightBtwnDateQuotationNLine

        // Draw Line
        currentX -= 2
        this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
        // page.drawLine({
        //     start: { x: currentX - 2, y: currentY },
        //     end: { x: width - xOffset + 4, y: currentY },
        //     thickness: 1,
        //     color: fontColor
        // })

        // Line start at
        currentX = xOffset
        currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
        this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight + dataLineHeight / 2)
        var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            (data.State ? data.State + "." : "") +
            (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
        this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
        page.drawLine({
            start: { x: currentX - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontQuotationColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor);

        // Grand Total
        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        var rowData = [``, ``, ``, `Subtotal`, this.formatMoney(data.Subtotal)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontQuotationColor)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = [``, ``, ``, `Rounding`, this.formatMoney(data.Rounding)]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontQuotationColor)

        currentX = width - xOffset;
        currentY -= (companyHeaderTextSize + tableLineHeight + tableHeaderTop)
        this.AddAlignRightTextNew(false, 'RM ' + this.formatMoney(data.GrandTotal), page, boldFont, currentX, currentY, companyHeaderTextSize, companyHeaderTextSize, fontTotalColor);

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + yOffset) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + yOffset;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
        this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
        currentY -= notesLineHeight;

        currentX = xOffset
        if (data.Notes) {
            var recNum = 1;
            for (var rec of data.Notes) {
                multiline = this.TextToLineArray(rec.Info, notesMaxChar)
                this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
                notesRowLine = multiline.length

                currentY -= (notesLineHeight * notesRowLine);

                recNum++;
            }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, ["This is computer generated quotation. No signature required."], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        this.addPageNumFooter(pdfDoc);

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }

    static async drawLine(page, x, y, width, thickness = 1, color = cmyk(0.5, 0, 0, 0)) {
        page.drawLine({
            start: { x: x, y: y },
            end: { x: x + width, y: y },
            thickness: thickness,
            color: color
        })
    }

    static async addPageNumFooter(pdfDoc: PDFDocument) {
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const dataTextSize = 8
        const dataLineHeight = 10
        const paddingRight = 60
        const paddingBottom = 30

        const pages = pdfDoc.getPages()

        let currentPageCount = 1;
        for (var page of pages) {
            const { width, height } = page.getSize()
            this.AddAlignRightTextNew(false, `Page ${currentPageCount}/${pages.length}`, page, font, width - paddingRight, paddingBottom, dataLineHeight, dataTextSize)

            currentPageCount++;
        }
    }

    static async POS(data, isBase64 = false) {
        const pdfDoc = await PDFDocument.create();

        const pageHeight = 2000;

        var { pdfPage, pdfPageHeight } = await CommonPrint.getPOSPage(pdfDoc, data, pageHeight);

        await CommonPrint.getPOSPage(pdfDoc, data, pdfPageHeight);

        pdfDoc.removePage(0);

        if (isBase64) {
            const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
            return pdfDataUri;
        } else {
            const pdfDataUri = await pdfDoc.save();
            return pdfDataUri;
        }
    }

    static async getPOSPage(pdfDoc, data, pageHeight) {
        // const url = '/assets/fonts/RobotoMono-Medium.ttf'
        // const url = '/assets/fonts/Roboto-Medium.ttf'
        // const fontBytes = await fetch(url).then(res => res.arrayBuffer())

        // console.log('fontBytes')
        // console.log(fontBytes)

        // pdfDoc.registerFontkit(fontkit)
        // const font = await pdfDoc.embedFont(fontBytes);
        // const boldFont = await pdfDoc.embedFont(fontBytes);

        // Page Info
        var mmToPoint = 2.83465;
        const pageWidth = 58;
        var page = pdfDoc.addPage([pageWidth * mmToPoint, pageHeight]) // [550, 750]
        var { width, height } = page.getSize()

        // Font setting
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const headerTextSize = 10
        const headerLineHeight = 12
        const dataTextSize = 7
        const dataLineHeight = 7
        const tableLineHeight = 7;
        const tableLineSpacing = 0;

        const xOffset = 25
        const yOffset = 20

        const tableXOffset = 0;
        const tableHeaderBottom = 4;
        var currentRowLine = 1;

        var currentY = height - yOffset;
        var currentX = xOffset;

        var columnPercentage = [0.31, 0.23, 0.23, 0.23]
        var columnAlign = [false, true, true, true]
        var rowDataHeader = [data.HeaderDesc, data.HeaderUnitPrice, data.HeaderQty, data.HeaderTotal]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth]

        var bottomReservedSpace = 50;

        const maxWidth = width;

        var sectionSpacing = 10;

        // v2 Header Start
        var compName = this.TextToLineArray((data.IssuerCompanyName ? data.IssuerCompanyName : ""), 22);
        this.AddHorizontalCenterMultiLineText(compName, page, xOffset, currentY, headerLineHeight, headerTextSize, boldFont, maxWidth);

        currentY -= (headerLineHeight * compName.length);

        var compAddress = this.TextToLineArray(data.IssuerLine1 + "\n"
            + data.IssuerLine2 + "\n"
            + data.IssuerLine3 + "\n"
            + data.IssuerLine4, 36);
        this.AddHorizontalCenterMultiLineText(compAddress, page, xOffset, currentY, dataLineHeight, dataTextSize, font, maxWidth);

        currentY -= (dataLineHeight * compAddress.length);

        // Bill Date Ref No section
        currentY -= sectionSpacing;

        var title = this.TextToLineArray((data.PageTitle ? data.PageTitle : ""), 22);
        this.AddHorizontalCenterMultiLineInvertText(title, page, xOffset, currentY, headerLineHeight, dataTextSize, boldFont, tableWidth);

        currentY -= (dataLineHeight * title.length);

        // Bill Date Ref No section
        currentY -= sectionSpacing;

        currentX = xOffset + tableXOffset

        // Date
        var billInfoTable = [0.42 * tableWidth, 0.58 * tableWidth];

        currentRowLine = this.InsertTableLine(true, ['Date:', formatDate(data.IssueDate, 'yyyy-MM-dd', 'en')], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        // Ref No
        currentRowLine = this.InsertTableLine(true, ['Ref No:', data.RefNo], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        // Customer
        if (data.Customer) {
            // this.AddMultiLineText(false, compName, page, xOffset, currentY, headerLineHeight, headerTextSize, font, maxWidth);
            currentRowLine = this.InsertTableLine(true, ['Customer:', data.Customer.substring(0, 17)], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
            currentY -= (tableLineHeight * compName.length + tableLineSpacing);
        }

        // Table section
        currentY -= sectionSpacing;

        currentX += tableXOffset
        // currentY -= (tableLineHeight + tableHeaderTop)

        currentRowLine = this.InsertTableLine(true, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize);

        // Seperator Line
        currentY -= tableHeaderBottom

        // page.drawLine({
        //     start: { x: xOffset, y: currentY },
        //     end: { x: width - xOffset, y: currentY },
        //     thickness: 1,
        //     color: rgb(0.224, 0.224, 0.224),
        //     opacity: 0.6,
        // })

        // // Start Data
        if (data.Items) {
            for (var rec of data.Items) {
                if (!(rec.Desc && rec.UnitPrice && rec.Qty && rec.Total)) {
                    continue;
                }

                currentX = xOffset + tableXOffset
                currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

                // Split to Next Page if exceed reserved place
                if (currentY < bottomReservedSpace) {
                    page = pdfDoc.addPage([pageWidth * mmToPoint, pageHeight * mmToPoint])
                    currentY = height - yOffset
                }

                currentRowLine = this.InsertTableLine(true, [rec.Desc.toString()], [tableWidth], columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize)
                currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

                if (rec.Remark) {
                    currentRowLine = this.InsertTableLine(true, [rec.Remark.toString()], [tableWidth], columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
                    currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
                }

                currentRowLine = this.InsertTableLine(true, ['', this.formatMoney(rec.UnitPrice), this.formatMoney(rec.Qty), this.formatMoney(rec.Total)], colWidth, columnAlign, page, font, currentX, currentY, tableLineHeight, dataTextSize)
            }
        }

        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        // page.drawLine({
        //     start: { x: xOffset, y: currentY },
        //     end: { x: width - xOffset, y: currentY },
        //     thickness: 1,
        //     color: rgb(1, 0.224, 0.224),
        //     opacity: 0.6,
        // })

        // Summary section
        currentY -= sectionSpacing;

        // Summary start
        // Grand Total
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        // Split to Next Page if exceed reserved place
        if (currentY < bottomReservedSpace) {
            page = pdfDoc.addPage([pageWidth * mmToPoint, pageHeight * mmToPoint])
            currentY = height - yOffset
        }

        currentRowLine = this.InsertTableLine(true, ['TOTAL', data.GrandTotal], billInfoTable, columnAlign, page, boldFont, currentX, currentY, headerLineHeight, headerTextSize)

        // Tender
        if (data.Tender) {
            currentX = xOffset + tableXOffset
            currentY -= (headerLineHeight * currentRowLine + tableLineSpacing);

            // Split to Next Page if exceed reserved place
            if (currentY < bottomReservedSpace) {
                page = pdfDoc.addPage([pageWidth * mmToPoint, pageHeight * mmToPoint])
                currentY = height - yOffset
            }

            currentRowLine = this.InsertTableLine(true, ['TENDER', data.Tender], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
        }

        // Changes
        if (data.Changes) {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

            // Split to Next Page if exceed reserved place
            if (currentY < bottomReservedSpace) {
                page = pdfDoc.addPage([pageWidth * mmToPoint, pageHeight * mmToPoint])
                currentY = height - yOffset
            }

            currentRowLine = this.InsertTableLine(true, ['CHANGES', data.Changes], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
        }

        if (data.MemberInfo) {
            currentY -= sectionSpacing;

            // page.drawLine({
            //     start: { x: xOffset, y: currentY },
            //     end: { x: width - xOffset, y: currentY },
            //     thickness: 1,
            //     color: rgb(0.224, 0.224, 0.224),
            //     opacity: 0.6,
            // })

            // Summary section
            currentY -= sectionSpacing;

            currentY -= (tableLineHeight)

            currentRowLine = this.InsertTableLine(true, ['POINT SUMMARY'], [width], columnAlign, page, boldFont, currentX, currentY, headerLineHeight, headerTextSize)
            currentY -= (headerLineHeight * currentRowLine + tableLineSpacing);

            currentRowLine = this.InsertTableLine(true, ['Member ID', data.MemberInfo.ID], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
            currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
            currentRowLine = this.InsertTableLine(true, ['Previous', data.MemberInfo.OpeningPoint.toString()], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
            currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
            currentRowLine = this.InsertTableLine(true, ['Earn', '+ ' + data.MemberInfo.EarnPoint.toString()], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
            currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
            currentRowLine = this.InsertTableLine(true, ['Redeem', '- ' + data.MemberInfo.RedeemPoint.toString()], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
            currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
            currentRowLine = this.InsertTableLine(true, ['Balance', data.MemberInfo.ClosingPoint.toString()], billInfoTable, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize)
            currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);
        }

        // Computer generated
        currentX = xOffset

        let comeAgain = "Please come again!";
        let textWidth = font.widthOfTextAtSize(comeAgain, dataTextSize);

        page.drawText(comeAgain, {
            x: (maxWidth - textWidth) / 2,
            y: 30,
            maxWidth: maxWidth,
            lineHeight: dataLineHeight,
            size: dataTextSize,
            // color: color,
            // font: font
        })

        let thankYou = "Thank you!";
        textWidth = font.widthOfTextAtSize(thankYou, dataTextSize);

        page.drawText(thankYou, {
            x: (maxWidth - textWidth) / 2,
            y: 20,
            maxWidth: maxWidth,
            lineHeight: dataLineHeight,
            size: dataTextSize,
            // color: color,
            // font: font
        })

        return { pdfPage: page, pdfPageHeight: height - currentY + 50 };    // 50 for Footer
    }

    static async DeliveryNDS(data, isBase64 = false) {
        const pdfDoc = await PDFDocument.create();

        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = cmyk(0.0, 0.88, 0.41, 0.12);
        const fontInvoiceColor = cmyk(0.73, 0.64, 0.00, 0.43);

        const headerLineXOffset = 40
        const xOffset = 60
        const yOffset = 40
        const dataTextSize = 10
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 24

        const yBillToStart = 120
        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableHeaderBottom = 7;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentY = height - yBillToStart - dataLineHeight * 5 - spaceBetweenBillToNHeader;
        var currentX = xOffset;

        var columnPercentage = [0.1, 0.13, 0.67, 0.1]
        var columnAlign = [false, false, false, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderCode, data.HeaderDesc, data.HeaderQty]
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth]

        var bottomReservedSpace = 50;

        currentY = height - 10;

        let notesRowLine = 0;
        const notesMaxChar = 105;
        const notesMaxWidth = width - 2 * xOffset;
        const heightBtwnInvoiceNDateInvoice = 15;
        const heightBtwnDateInvoiceNLine = 10;
        const heightBtwnTopBarNCompanyName = 35;

        // Calculate required Footer size
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotes(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight('This invoice is computer generated, no signature is requried.', notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + yOffset + totalHeightRequiredHeight

        // Header START
        // Draw Header Line
        this.drawLine(page, headerLineXOffset, currentY, width - 2 * headerLineXOffset, 5, fontInvoiceColor);
        currentY -= heightBtwnTopBarNCompanyName

        // Draw Issuer Name, RegNo, Address
        var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
            (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
            (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
            (data.IssuerState ? data.IssuerState + "." : "") +
            (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

        let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

        currentX = xOffset;
        currentY = y;

        // Draw Invoice Title
        this.AddMultiLineText(false, [data.Invoice], billToMaxWidth, page, boldFont, currentX, currentY, headerTextSize, headerTextSize, fontInvoiceColor);
        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)

        // Draw QR Code
        if(data.ShowQRCode) {
            let qrCode = await this.getBarcodeData(environment.webURL + '/tx/sd/' + data.ID, "").toDataURL("image/jpeg");
            const jpgImage = await pdfDoc.embedJpg(qrCode);
            page.drawImage(jpgImage, {
                x: width - xOffset - 50,
                y: currentY - 20,
                width: 50,
                height: 50,
                // rotate: degrees(30),
                // opacity: 0.75,
            })

            page.drawText(data.DeliveryNo.slice(data.DeliveryNo.length - 6), {
                x: width - xOffset - 42,
                y: currentY - 27,
                size: 10,
                // rotate: degrees(30),
                // opacity: 0.75,
            })
        }

        // Draw Invoice Info
        var iiColumnPercentage = [0.3, 0.3, 0.4]
        var iiColumnAlign = [false, false, false]
        var iiRowDataHeader = ['Date', 'Order', 'Delivery']
        var iiRowData = [data.InvoiceDate + '', data.OrderNo, data.DeliveryNo]
        var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth]
        currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

        currentY -= heightBtwnDateInvoiceNLine

        // Draw Line
        currentX -= 2
        this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
        // page.drawLine({
        //     start: { x: currentX - 2, y: currentY },
        //     end: { x: width - xOffset + 4, y: currentY },
        //     thickness: 1,
        //     color: fontColor
        // })

        // Line start at
        currentX = xOffset
        currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
        this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight + dataLineHeight / 2)
        var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            (data.State ? data.State + "." : "") +
            (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
        this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
        page.drawLine({
            start: { x: currentX - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor, true, true, true, true);

        // Grand Total
        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        var rowData = [``, ``, ``]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop)

        rowData = [``, ``, ``]
        currentRowLine = this.InsertTableLine(false, rowData, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor)

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + yOffset) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + yOffset;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
        this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
        currentY -= notesLineHeight;

        currentX = xOffset
        if (data.Notes) {
            var recNum = 1;
            for (var rec of data.Notes) {
                multiline = this.TextToLineArray(rec.Info, notesMaxChar)
                this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
                notesRowLine = multiline.length

                currentY -= (notesLineHeight * notesRowLine);

                recNum++;
            }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, ["This is computer generated. No signature required."], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        this.addPageNumFooter(pdfDoc);

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }

    static getBarcodeData(text: string, labal: string, size = 900): HTMLCanvasElement {
        return kjua({
            render: "canvas",
            crisp: true,
            minVersion: 1,
            ecLevel: "Q",
            size: size,
            ratio: undefined,
            fill: "#333",
            back: "#fff",
            text,
            rounded: 10,
            quiet: 2,
            mode: "label",
            mSize: 5,
            mPosX: 50,
            mPosY: 100,
            label: labal,
            fontname: "sans-serif",
            fontcolor: "#3F51B5",
            image: undefined
        });
    }
    

    static async ProductionGeneric(data, isBase64 = false) {
        // console.log(data);
        
        // Create PDF document
        const pdfDoc = await PDFDocument.create();

        // Add new page to PDF
        var page = pdfDoc.addPage(PageSizes.A4) // [550, 750]

        // Define settings
        const { width, height } = page.getSize()
        const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
        const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);
        const fontColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.0, 0.0, 0.0, 0.6);
        const fontTotalColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.0, 0.88, 0.41, 0.12);
        const fontInvoiceColor = data.BlackAndWhite ? cmyk(0.0, 0.0, 0.0, 1) : cmyk(0.73, 0.64, 0.00, 0.43);

        const xOffset = data.XOffset
        const yOffset = data.YOffset
        const dataTextSize = 9
        const dataLineHeight = 12
        const notesTextSize = 9
        const notesLineHeight = 10

        const companyHeaderTextSize = 18
        const headerTextSize = 20

        const tableXOffset = 0;
        const billToMaxWidth = width - 2 * xOffset - 2 * tableXOffset;
        const tableLineHeight = 12;
        const spaceBetweenBillToNHeader = 10;
        const tableHeaderTop = 3;
        const tableLineSpacing = 6;
        var currentRowLine = 1;
        var currentX = xOffset;

        const heightBtwnInvoiceNDateInvoice = 15;

        // Calculate Table Column width
        var tableWidth = width - 2 * xOffset - 2 * tableXOffset
        var columnPercentage = [0.07, 0.13, 0.7, 0.1]
        var columnAlign = [false, false, false, true]
        var rowDataHeader = [data.HeaderNo, data.HeaderCode, data.HeaderDesc, data.HeaderQty]
        var colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth, columnPercentage[3] * tableWidth]

        // Recalculate Table Column width if Hide Code
        if (data.ShowCode === false) {
            columnPercentage = [0.07, 0.83, 0.1]
            columnAlign = [false, false, true]
            rowDataHeader = [data.HeaderNo, data.HeaderDesc, data.HeaderQty]
            colWidth = [columnPercentage[0] * tableWidth, columnPercentage[1] * tableWidth, columnPercentage[2] * tableWidth]
        }

        // Page Y starting
        var currentY = height - yOffset - 14;

        // Header START
        if (data.ShowCompany === true) {
            // Draw Issuer Name, RegNo, Address
            var issuerAddr = (data.IssuerAddressLine1 ? data.IssuerAddressLine1 + "\n" : "") +
                (data.IssuerAddressLine2 ? data.IssuerAddressLine2 + "\n" : "") +
                (data.IssuerPostCode ? data.IssuerPostCode + (data.IssuerState ? "," : ".") : "") +
                (data.IssuerState ? data.IssuerState + "." : "") +
                (data.IssuerContactNo ? "\n" + data.IssuerContactNo : "")

            let { x, y } = this.drawIssuerContent(page, currentX, currentY, [data.IssuerCompanyName], data.IssuerCompanyRegNo, issuerAddr, boldFont, companyHeaderTextSize, font, dataTextSize, fontColor);

            currentX = xOffset;
            currentY = y;
        }

        // Draw Invoice Title
        this.AddHorizontalCenterMultiLineText([data.Invoice], page, xOffset, currentY, headerTextSize, headerTextSize, boldFont, width, fontInvoiceColor);
        currentY -= (heightBtwnInvoiceNDateInvoice + headerTextSize)

        const notesMaxChar = 105;
        const heightBtwnDateInvoiceNLine = 10;

        // // Draw QR Code
        // let qrCode = await this.getBarcodeData(environment.webURL + '/tx/' + data.InvoiceNo.substring(0, 2) + '/' + data.ID, "").toDataURL("image/jpeg");
        // const jpgImage = await pdfDoc.embedJpg(qrCode);
        // page.drawImage(jpgImage, {
        //     x: width - xOffset - 50,
        //     y: currentY - 20,
        //     width: 50,
        //     height: 50,
        //     // rotate: degrees(30),
        //     // opacity: 0.75,
        // })

        // page.drawText(data.InvoiceNo.slice(data.InvoiceNo.length - 6), {
        //     x: width - xOffset - 42,
        //     y: currentY - 27,
        //     size: 10,
        //     // rotate: degrees(30),
        //     // opacity: 0.75,
        // })

        if (data.TopDown) {
            // Draw Invoice Info
            var iiColumnPercentage = [0.3, 0.3, 0.4]
            var iiColumnAlign = [false, false, false]
            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderSalesPerson ? data.HeaderSalesPerson : 'Sales Person']
            var iiRowData = [data.InvoiceDate + '', data.InvoiceNo, data.SalesPerson]
            var iiColWidth = [iiColumnPercentage[0] * tableWidth, iiColumnPercentage[1] * tableWidth, iiColumnPercentage[2] * tableWidth]
            currentY = this.InsertTableWData(iiRowDataHeader, iiRowData, iiColWidth, iiColumnAlign, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentY -= heightBtwnDateInvoiceNLine

            // Draw Line
            currentX -= 2
            this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // page.drawLine({
            //     start: { x: currentX - 2, y: currentY },
            //     end: { x: width - xOffset + 4, y: currentY },
            //     thickness: 1,
            //     color: fontColor
            // })

            // Line start at
            currentX = xOffset
            currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight + dataLineHeight / 2)
            var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
                (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
                (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
                (data.State ? data.State + "." : "") +
                (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
            this.AddMultiLineText(false, billToData, billToMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            currentY -= (dataLineHeight * billToData.length + spaceBetweenBillToNHeader)
            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })
        } else {
            // Line start at
            let startCurrentY = currentY;

            // currentX = xOffset
            // // currentY -= (spaceBetweenBillToNHeader + dataLineHeight)
            // this.AddMultiLineText(false, [data.CompanyName], billToMaxWidth / 2, page, boldFont, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            // currentY -= (dataLineHeight + dataLineHeight / 2)
            // // var billToData = this.TextToLineArray((data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            // //     (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            // //     (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            // //     (data.State ? data.State + "." : "") +
            // //     (data.ContactNo ? "\n" + data.ContactNo : ""), notesMaxChar);
                
            //  var billToDataRaw = (data.AddressLine1 ? data.AddressLine1 + "\n" : "") +
            //     (data.AddressLine2 ? data.AddressLine2 + "\n" : "") +
            //     (data.PostCode ? data.PostCode + (data.State ? "," : ".") : "") +
            //     (data.State ? data.State + "." : "") +
            //     (data.ContactNo ? "\n" + data.ContactNo : "");

            // let paragraphResult = this.fillParagraph(billToDataRaw, font, dataTextSize, billToMaxWidth / 2);

            // let rowLine = paragraphResult.split('\n').length;

            // this.AddMultiLineText(false, [paragraphResult], billToMaxWidth / 2, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

            // currentY -= (dataLineHeight * rowLine)
            // // page.drawLine({
            // //     start: { x: currentX - 2, y: currentY },
            // //     end: { x: width - xOffset + 4, y: currentY },
            // //     thickness: 1,
            // //     color: fontColor
            // // })

            var iiRowDataHeader = [data.HeaderDate ? data.HeaderDate : 'Date', data.HeaderInvoice ? data.HeaderInvoice : 'Invoice', data.HeaderRemark ? data.HeaderRemark : 'Remark']
            var iiRowData = [data.InvoiceDate + '', data.Formula, data.Remark]
            // var iiColWidth = [iiColumnPercentage[0] * billToMaxWidth / 2, iiColumnPercentage[1] * billToMaxWidth / 2]
            currentX = xOffset

            currentY = this.InsertTableWDataVertical(iiRowDataHeader, iiRowData, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            let endY = currentY;

            currentY = startCurrentY;

            // Draw Invoice Info
            // var iiColumnPercentage = [0.3, 0.7]
            // var iiColumnAlign = [true, true]
            
            iiRowDataHeader = [data.HeaderStartTime ? data.HeaderStartTime : 'Start Time', data.HeaderEndTime ? data.HeaderEndTime : 'End Time', data.HeaderTimeUsed ? data.HeaderTimeUsed : 'Time Used']
            iiRowData = [data.StartTime, data.EndTime, data.TimeUsed]
            // var iiColWidth = [iiColumnPercentage[0] * billToMaxWidth / 2, iiColumnPercentage[1] * billToMaxWidth / 2]
            currentX += billToMaxWidth / 2;

            currentY = this.InsertTableWDataVertical(iiRowDataHeader, iiRowData, page, currentX, currentY, tableLineHeight, boldFont, dataTextSize, font, fontColor, fontInvoiceColor);

            currentX = xOffset

            currentY -= heightBtwnDateInvoiceNLine

            if(endY < currentY) {
                currentY = endY;
            }

            page.drawLine({
                start: { x: currentX - 2, y: currentY },
                end: { x: width - xOffset + 4, y: currentY },
                thickness: 1,
                color: fontColor
            })

            // // Draw Line
            // currentX -= 2
            // this.drawLine(page, currentX, currentY, width - 2 * currentX, 1, fontColor)
            // // page.drawLine({
            // //     start: { x: currentX - 2, y: currentY },
            // //     end: { x: width - xOffset + 4, y: currentY },
            // //     thickness: 1,
            // //     color: fontColor
            // // })


        }

        var bottomReservedSpace = 50;

        let notesRowLine = 0;
        const notesMaxWidth = width - 2 * xOffset;

        const declaration = data.Declaration;

        // Calculate required Footer size
        let spacingBetweenPageBottom = 40;
        let notesRequiredHeight = data.Notes ? this.calculateRequiredHeightForNotesGeneric(data.Notes, notesMaxWidth, dataLineHeight) + dataLineHeight : 0;
        let remarkRequiredHeight = this.calculateSingleLineDataHeight(declaration, notesMaxWidth, dataLineHeight) + dataLineHeight
        let totalHeightRequiredHeight = companyHeaderTextSize + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + tableLineHeight + tableHeaderTop + dataLineHeight

        let requiredSizeForLowerArea = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom + totalHeightRequiredHeight

        currentX += tableXOffset
        currentY -= (tableLineHeight + tableHeaderTop + 20)

        currentRowLine = this.InsertTableLine(false, rowDataHeader, colWidth, columnAlign, page, boldFont, currentX, currentY, tableLineHeight, dataTextSize, fontInvoiceColor);

        // Seperator Line
        currentX = xOffset + tableXOffset
        currentY -= (tableLineHeight * currentRowLine + tableLineSpacing);

        for(var item of data.Items) {
            if(!item.Remark) {
                item.Remark = '';
            }

            if(item.QRTransaction) {
                for(var itemQr of item.QRTransaction) {
                    item.Remark += `QR: ${itemQr.QRCode} Qty: ${itemQr.Qty}\n`;
                }
            }
        }

        currentY = this.prepareTableContent(data.Items, currentX, currentY, pdfDoc, page, tableWidth, tableLineHeight, tableLineSpacing, bottomReservedSpace, yOffset, requiredSizeForLowerArea, colWidth, columnAlign, font, dataTextSize, fontColor, data.IsStripedRow, data.ShowCode, data.ShowUnitName, true);

        // Grand Total
        // Get last page
        page = pdfDoc.getPage(pdfDoc.getPageCount() - 1);

        page.drawLine({
            start: { x: xOffset - 2, y: currentY },
            end: { x: width - xOffset + 4, y: currentY },
            thickness: 1,
            color: fontColor
        })

        if (currentY < totalHeightRequiredHeight) {
            // console.log('add page in invoiceGeneric')

            page = pdfDoc.addPage(PageSizes.A4)
            currentY = totalHeightRequiredHeight;
        }
        else {
            currentX = xOffset + tableXOffset
            currentY -= (tableLineHeight + tableHeaderTop)
        }

        // Signing Location
        currentX = xOffset

        if (currentY < notesRequiredHeight + spacingBetweenPageBottom) {
            page = pdfDoc.addPage(PageSizes.A4)
            currentY = notesRequiredHeight + remarkRequiredHeight + spacingBetweenPageBottom;
        }
        else {
            currentY -= companyHeaderTextSize
        }

        if (data.HeaderNotes) {
            var multiline = this.TextToLineArray(data.HeaderNotes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            currentY -= notesLineHeight;
        }

        currentX = xOffset
        if (data.Notes) {
            // var recNum = 1;
            // for (var rec of data.Notes) {
            multiline = this.TextToLineArray(data.Notes, notesMaxChar)
            this.AddMultiLineText(false, multiline, notesMaxWidth, page, font, currentX, currentY, notesLineHeight, notesTextSize, fontColor);
            notesRowLine = multiline.length

            currentY -= (notesLineHeight * notesRowLine);

            // recNum++;
            // }
        }

        currentY -= notesLineHeight;

        // Computer generated
        this.AddMultiLineText(false, [declaration], notesMaxWidth, page, font, currentX, currentY, dataLineHeight, dataTextSize, fontColor);

        let pdfDataUri;

        // Add Page Number
        if (data.ShowFooter === true) {
            this.addPageNumFooter(pdfDoc);
        }

        // Draw page background
        if (data.Attachments && data.Attachments.length > 0) {
            const bgBytes = await fetch(data.Attachments[0].AttachmentPath + '?CID=' + data.CompanyID).then(res => res.arrayBuffer())

            const background = await pdfDoc.embedPng(bgBytes);

            for (var pg of pdfDoc.getPages()) {
                pg.drawImage(background, {
                    x: 0,
                    y: 0,
                    width: width,
                    height: height,
                })
            }
        }

        // if(data.background) {
        //     console.log('draw background')
        //     const background = await pdfDoc.embedPng(data.background);

        //     for(var pg of pdfDoc.getPages()) {
        //         pg.drawImage(background, {
        //             x: 0,
        //             y: 0,
        //             width: width,
        //             height: height,
        //         })
        //     }
        // }

        if (isBase64) {
            pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
        } else {
            pdfDataUri = await pdfDoc.save()
        }

        return pdfDataUri;
    }
}