import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'receiptCompNoFilter'
})
export class ReceiptCompNoPipe implements PipeTransform {
  transform(value: string): any {
    let compName = null;

    if (value) {
      let oriLines = value.split('\n');
      let lines;

      let regNo = /(19[5-9]\d|20[0-4]\d|2050)0[1-6]{1}[0-9]{6}([^0-9]{1}|$)/g;

      if (oriLines) {
        lines = oriLines.map(x => {
          let matchingResult = x.toLowerCase().match(regNo);

          if (matchingResult != null) {
            return matchingResult.map(x => x.trim().replace(/\D/g, '')).join('\n')
          }

          return null;
        }).filter(x => x != null);

        if (lines && lines.length > 0) {
          compName = lines.join('\n');
        }
      }
    }

    return compName;
  }
}
