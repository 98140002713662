import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { ROUTES_Company } from "app/shared/vertical-menu/vertical-menu-routes.config";

@Component({
  selector: "app-company-layout",
  template: `<app-full-layout [routing]="routing"></app-full-layout>`,
})
export class CompanyLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  public routing = ROUTES_Company;

  constructor(
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  ngOnDestroy() {
  }
}
