<!-- <div class="card">
    <div class="card-content">
        <div class="card-body"> -->
            <button type="button" class="btn btn-success mr-1 mb-1" *ngIf="showSave" (click)="onSaveButton()"><i class="ft-save mr-1 mb-1"></i>Save</button>
            <button type="button" class="btn btn-success mr-1 mb-1" *ngIf="showSaveWithComment" (click)="onSaveWithCommentButton()"><i class="ft-save mr-1 mb-1"></i>Save With Comment</button>
            <button type="button" class="btn btn-danger mr-1 mb-1" *ngIf="showDelete" (click)="onDeleteButton()"><i class="ft-trash-2 mr-1 mb-1"></i>Delete</button>
            <button type="button" class="btn btn-secondary mr-1 mb-1" *ngIf="showClose" (click)="onCloseButton()"><i class="ft-x mr-1 mb-1"></i>Close</button>
            <button type="button" class="btn btn-primary mr-1 mb-1" *ngIf="showPrint" (click)="onPrintButton()"><i class="ft-printer mr-1 mb-1"></i>Print</button>
            <button type="button" class="btn btn-success mr-1 mb-1" *ngIf="showDuplicate" (click)="onDuplicateButton()"><i class="ft-copy mr-1 mb-1"></i>Duplicate</button>
            <button type="button" class="btn btn-success mr-1 mb-1" *ngIf="showSign" (click)="onSignButton()"><i class="ft-feather mr-1 mb-1"></i>Sign</button>
            <button type="button" class="btn btn-primary mr-1 mb-1" *ngIf="showPrintDotMatrix" (click)="onPrintDotMatrixButton()"><i class="ft-printer mr-1 mb-1"></i>Print Dot Matrix</button>
        <!-- </div>
    </div>
</div> -->