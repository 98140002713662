import { Pipe, PipeTransform } from '@angular/core';
import { CommonPrint } from '../common/common_print';

@Pipe({
  name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
  transform(value: number): any {
    if (!value) {
      return '0 KB';
    }

    if (isNaN(Number(value))) {
      return '0 KB';
    }

    if (value / 1024 / 1024 / 1024 < 1) {
      return CommonPrint.formatMoney(value / 1024 / 1024) + ' MB'
    } else {
      return CommonPrint.formatMoney(value / 1024 / 1024 / 1024) + ' GB'
    }
  }
}
