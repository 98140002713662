import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'receiptContactFilter'
})
export class ReceiptContactPipe implements PipeTransform {
  transform(value: string, isMobileNumber: boolean): any {
    let compName = null;

    if (value) {
      let oriLines = value.split('\n');
      let lines;

      let localPhone = /([^0-9a-zA-Z]{1}|^)([\(]|)([\+]6|6|)0[2-9]{1}([\)].|.|)[0-9]{3,4}(.)[0-9]{4,5}([^0-9]{1}|$)/g;
      let mobilePhone = /([^0-9a-zA-Z]{1}|^)([\(]|)([\+]6|6|)0[1]{1}[0-9]{1}([\)].|.|)[0-9]{3,4}(.)[0-9]{3,5}([^0-9]{1}|$)/g;

      if (oriLines) {
        lines = oriLines.map(x => {
          let matchingResult = x.toLowerCase().match(isMobileNumber ? mobilePhone : localPhone);

          if (matchingResult != null) {
            return matchingResult.map(x => x.trim().replace(/\D/g,'')).join('\n')
          }

          return null;
        }).filter(x => x != null);

        if (lines && lines.length > 0) {
          compName = lines.join('\n');
        }
      }
    }

    return compName;
  }
}
