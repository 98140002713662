import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const ISO_ROUTES: Routes = [
  {
    path: 'iso',
    loadChildren: () => import('../../iso/iso.module').then(m => m.ISOModule)
  },
  {
    path: 'dynamic',
    loadChildren: () => import('../../dynamic-page/dynamic-page.module').then(m => m.DynamicPageModule)
  },
];
