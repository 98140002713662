import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Inventory_ROUTES: Routes = [
  {
    path: 'setting',
    loadChildren: () => import('../../setting/setting.module').then(m => m.SettingModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('../../inventory/inventory.module').then(m => m.InventoryModule)
  },
];
