import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keyDataSelector'
})
export class KeyDataSelectorPipe implements PipeTransform {
  transform(item: any, key: string, labelKey?: string): any {
    if (!key) {
      return item;
    }

    if (!item || !key) {
      return null;
    }

    // try split key
    var keys = key.split('.');

    var data = item;

    for(var k of keys) {
      data = data[k];
    }

    return data;
  }
}
