import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { ContentNavLayoutComponent } from "./layouts/content-nav/content-nav-layout.component";
import { BillingLayoutComponent } from "./layouts/billing/billing-layout.component";
import { DeviceLayoutComponent } from "./layouts/device/device-layout.component";
import { ISOLayoutComponent } from "./layouts/iso/iso-layout.component";
import { PublicLayoutComponent } from "./layouts/public/public-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { CONTENT_NAV_ROUTES } from "./shared/routes/content-nav-layout.routes";
import { Billing_ROUTES } from "./shared/routes/billing-layout.routes";
import { Device_ROUTES } from "./shared/routes/device-layout.routes";
import { ISO_ROUTES } from "./shared/routes/iso-layout.routes";
import { Inventory_ROUTES } from "./shared/routes/inventory-layout.routes";
import { Public_ROUTES } from "./shared/routes/public-layout.routes";
import { Company_ROUTES } from "./shared/routes/company-layout.routes";

import { AuthGuard } from './shared/auth/auth-guard.service';
import { InventoryLayoutComponent } from './layouts/inventory/inventory-layout.component';
import { CompanyLayoutComponent } from "./layouts/company/company-layout.component";

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'pages/login',
    pathMatch: 'full',
  },
  { path: '', component: FullLayoutComponent, data: { title: 'full Views' }, children: Full_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ContentLayoutComponent, data: { title: 'content Views' }, children: CONTENT_ROUTES },
  { path: '', component: ContentNavLayoutComponent, data: { title: 'full Views' }, children: CONTENT_NAV_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: BillingLayoutComponent, data: { title: 'billing Views' }, children: Billing_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: DeviceLayoutComponent, data: { title: 'device Views' }, children: Device_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: ISOLayoutComponent, data: { title: 'iso Views' }, children: ISO_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: InventoryLayoutComponent, data: { title: 'inventory Views' }, children: Inventory_ROUTES, canActivate: [AuthGuard] },
  { path: '', component: PublicLayoutComponent, data: { title: 'public Views' }, children: Public_ROUTES },
  { path: '', component: CompanyLayoutComponent, data: { title: 'company Views' }, children: Company_ROUTES, canActivate: [AuthGuard] },
  {
    path: '**',
    redirectTo: 'pages/error'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
