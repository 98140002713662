import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayModule } from '@angular/cdk/overlay';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ClickOutsideModule } from 'ng-click-outside';

import { AutocompleteModule } from './components/autocomplete/autocomplete.module';
import { PipeModule } from 'app/shared/pipes/pipe.module';

//COMPONENTS
import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { HorizontalMenuComponent } from './horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from "./vertical-menu/vertical-menu.component";
import { CustomizerComponent } from './customizer/customizer.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { LangbarComponent } from "./langbar/langbar.component";
import { NoDataComponent } from "./no-data/no-data.component";
import { LoadingDataComponent } from "./loading-data/loading-data.component";
import { FilterBarComponent } from "./filter-bar/filter-bar.component";
import { SpanMultiLangComponent } from "./components/span-multi-lang/span-multi-lang.component";
import { NumPadComponent } from "./components/num-pad/num-pad.component";
import { CashierNumPadComponent } from "./components/cashier-num-pad/cashier-num-pad.component";

//DIRECTIVES
import { ToggleFullscreenDirective } from "./directives/toggle-fullscreen.directive";
import { SidebarLinkDirective } from './directives/sidebar-link.directive';
import { SidebarDropdownDirective } from './directives/sidebar-dropdown.directive';
import { SidebarAnchorToggleDirective } from './directives/sidebar-anchor-toggle.directive';
import { SidebarDirective } from './directives/sidebar.directive';
import { TopMenuDirective } from './directives/topmenu.directive';
import { TopMenuLinkDirective } from './directives/topmenu-link.directive';
import { TopMenuDropdownDirective } from './directives/topmenu-dropdown.directive';
import { TopMenuAnchorToggleDirective } from './directives/topmenu-anchor-toggle.directive';

import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { GalleryUploaderComponent } from './gallery-uploader/gallery-uploader.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ActionBarComponent } from './action-bar/action-bar.component';
// import { POSBarComponent } from './pos-bar/pos-bar.component';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//     suppressScrollX: true
//   };

@NgModule({
    exports: [
        CommonModule,
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarDirective,
        TopMenuDirective,
        NgbModule,
        TranslateModule,
        AccessDeniedComponent,
        GalleryUploaderComponent,
        PipeModule,
        LangbarComponent,
        NoDataComponent,
        LoadingDataComponent,
        FilterBarComponent,
        SpanMultiLangComponent,
        ActionBarComponent,
        NumPadComponent,
        CashierNumPadComponent,
        PerfectScrollbarModule
        // POSBarComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule ,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        ImageCropperModule
    ],
    declarations: [
        FooterComponent,
        NavbarComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        CustomizerComponent,
        NotificationSidebarComponent,
        ToggleFullscreenDirective,
        SidebarLinkDirective,
        SidebarDropdownDirective,
        SidebarAnchorToggleDirective,
        SidebarDirective,
        TopMenuLinkDirective,
        TopMenuDropdownDirective,
        TopMenuAnchorToggleDirective,
        TopMenuDirective,
        AccessDeniedComponent,
        GalleryUploaderComponent,
        LangbarComponent,
        NoDataComponent,
        LoadingDataComponent,
        FilterBarComponent,
        SpanMultiLangComponent,
        ActionBarComponent,
        NumPadComponent,
        CashierNumPadComponent,
        // POSBarComponent
    ],
    // providers: [
    //     {
    //       provide: PERFECT_SCROLLBAR_CONFIG,
    //       useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    //     }
    // ],
})
export class SharedModule { }
