import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {
  transform(items: any[], searchTerm: string, tableContent?: any[]): any {
    if (!searchTerm) {
      return items;
    }

    if (!items || !searchTerm) {
      return null;
    }

    return items.filter(
      item => {
        if(tableContent) {
          for(var content of tableContent) {
            var keys = content.key.split('.');

            var data = item;

            for(var k of keys) {
              data = data[k];
            }

            if (data?.toString().toLowerCase().includes(searchTerm.toLowerCase()) === true) {
              return true;
            }
          }
        } else {
          for(var key of Object.keys(item)) {
            if (item[key]?.toString().toLowerCase().includes(searchTerm.toLowerCase()) === true) {
              return true;
            }
          }
        }

        return false;
      }
    );
  }
}
