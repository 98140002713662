import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-data',
  template: `<div class="card text-center">
  <div class="card-header">
      <h4 class="card-title success">Loading Data</h4>
  </div>
  <div class="card-content">
      <div class="card-body">
          <i class="ft-loader spinner font-medium-3"></i>
      </div>
  </div>
</div>`
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class LoadingDataComponent {

  constructor() {
  }

}
