import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-no-data',
  template: `<div class="card text-center">
      <div class="card-header">
          <h4 class="card-title warning">No Data</h4>
      </div>
      <div class="card-content">
          <div class="card-body">
              <i class="ft-pie-chart font-medium-3"></i>

              <!--<div class="text-center mt-2">
                  <button type="button" class="btn btn-success" (click)="onClick()">Add</button>
              </div>-->
          </div>
      </div>
  </div>`
})

// https://medium.com/@majdasab/implementing-control-value-accessor-in-angular-1b89f2f84ebf
export class NoDataComponent {

  @Output() click: EventEmitter<any> = new EventEmitter();

  constructor() {
  }

  onClick() {
    this.click.emit();

  }

}
