import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
// import { AngularFireAuth } from "@angular/fire/auth";
// import { User } from 'firebase';
import { Observable } from 'rxjs';
import { LocalStorageType } from '../model/system';

import { KJUR, b64utoutf8 } from "jsrsasign";
import { CompanyEmployee, CompanyEmployeeModuleInfo, CompanyInfo } from '../model/company';
import { Member } from '../model/member';

@Injectable()
export class AuthService {
  // private user: Observable<firebase.User>;
  // private userDetails: firebase.User = null;

  // constructor(public _firebaseAuth: AngularFireAuth, public router: Router) {
  //   this.user = _firebaseAuth.authState;
  //   this.user.subscribe(
  //     (user) => {
  //       if (user) {
  //         this.userDetails = user;
  //       }
  //       else {
  //         this.userDetails = null;
  //       }
  //     }
  //   );

  // }

  // signupUser(email: string, password: string) {
  //   //your code for signing up the new user
  // }

  // signinUser(email: string, password: string) {
  //   //your code for checking credentials and getting tokens for for signing in user
  //   // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

  //   //uncomment above firebase auth code and remove this temp code
  //   return new Promise(function(resolve, reject) {
  //     setTimeout(function() {
  //       resolve(true);
  //     }, 1000);
  //   });

  // }

  // logout() {
  //   this._firebaseAuth.signOut();
  //   this.router.navigate(['YOUR_LOGOUT_URL']);
  // }

  // isAuthenticated() {
  //   return true;
  // }
  constructor(private router: Router,
    // private ngZone: NgZone
  ) { }

  signupUser(email: string, password: string) {
    //your code for signing up the new user
  }

  signinUser(email: string, password: string) {
    //your code for checking credentials and getting tokens for for signing in user
  }

  logout(systemLogout: boolean) {
    // this.token = null;
    // this.company = null;
    localStorage.removeItem(LocalStorageType.AccessToken);
    localStorage.removeItem(LocalStorageType.CompanyID);
    localStorage.removeItem(LocalStorageType.Session);

    // if (CommonFunction.IsISOPlatform()) {
    //   this.router.navigateByUrl('/pages/landing')
    // } else if (CommonFunction.IsAppPlatform()) {
    //   window.location.reload();
    // } else {
    //   this.router.navigateByUrl('/pages/home')
    // }

    // Navigate to Home page instead of Login page
    if (systemLogout) {
      if (this.router.url == '/pages/login') {
        this.router.navigateByUrl('/pages/login')
      } else {
        this.router.navigateByUrl('/pages/login?ReturnPath=' + this.router.url)
      }
    } else {
      // If User logout, go to Home page
      this.router.navigateByUrl('')
    }
  }

  getToken() {
    return localStorage.getItem(LocalStorageType.AccessToken);//this.token;
  }

  getCompany() {
    return localStorage.getItem(LocalStorageType.CompanyID);//this.company;
  }

  getMember(): Member {
    return JSON.parse(localStorage.getItem(LocalStorageType.Member));//this.token;
  }

  getEmployee(): CompanyEmployee {
    return JSON.parse(localStorage.getItem(LocalStorageType.Employee));//this.token;
  }

  getCompanyInfo() {
    return JSON.parse(localStorage.getItem(LocalStorageType.CompanyInfo)) as CompanyInfo;//this.token;
  }

  getEmployeeModule() {
    return JSON.parse(localStorage.getItem(LocalStorageType.EmployeeModule)) as CompanyEmployeeModuleInfo[];//this.token;
  }

  setToken(token: string) {
    // this.emitMemberChange(token)
    return localStorage.setItem(LocalStorageType.AccessToken, token);//this.token;
  }

  setCompany(company: string) {
    return localStorage.setItem(LocalStorageType.CompanyID, company);//this.company;
  }

  setCompanyInfo(company: CompanyInfo) {
    return localStorage.setItem(LocalStorageType.CompanyInfo, JSON.stringify(company));//this.company;
  }

  setMember(member: Member) {
    return localStorage.setItem(LocalStorageType.Member, JSON.stringify(member));//this.company;
  }

  setEmployee(emply: CompanyEmployee) {
    return localStorage.setItem(LocalStorageType.Employee, JSON.stringify(emply));//this.company;
  }

  setEmployeeModule(modules: CompanyEmployeeModuleInfo[]) {
    return localStorage.setItem(LocalStorageType.EmployeeModule, JSON.stringify(modules));//this.company;
  }

  isAuthenticated() {
    // // here you can check if user is authenticated or not through his token 
    // if(localStorage.getItem(LocalStorageType.AccessToken)) {
    return true;
    // } else {
    //   return false;
    // }
  }

  private cert = '-----BEGIN PUBLIC KEY-----\r\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA0kBIpmxM4ddrMnOeh7Ij\r\nI7PFmAx/NZcdgTOyhG5ahl20vESarwI/gcB7++3AR6nYnTF5Qzmub7z9ACGnu7UO\r\nuWoDX6rtacBfM/0+uPG3egtFz8fSSVb6NDJksvHO8ciaK4JW5Aa3bDtcyZtQz3rV\r\nZul6rloKChFMtiyqm1WrcrQE1w/ZjEv4PEiQZMB2t2h4DDkRlK+tDS06q6QTHCju\r\njTaLaXAF/gYBDgnYBjXX0Ji4lxvz3zLWW0HzK8zM7OIkD28VHAQmCWDWzq0yNKR7\r\nAb8jw2HcZ3rGhx5u5D1jAR9qXSLKBjB37fYFpSasfgqW4vLDmbOkkQafX7jh87kk\r\ndQIDAQAB\r\n-----END PUBLIC KEY-----\r\n'
  // `-----BEGIN RSA PUBLIC KEY-----
  // MIIBCgKCAQEA0kBIpmxM4ddrMnOeh7IjI7PFmAx/NZcdgTOyhG5ahl20vESarwI/
  // gcB7++3AR6nYnTF5Qzmub7z9ACGnu7UOuWoDX6rtacBfM/0+uPG3egtFz8fSSVb6
  // NDJksvHO8ciaK4JW5Aa3bDtcyZtQz3rVZul6rloKChFMtiyqm1WrcrQE1w/ZjEv4
  // PEiQZMB2t2h4DDkRlK+tDS06q6QTHCjujTaLaXAF/gYBDgnYBjXX0Ji4lxvz3zLW
  // W0HzK8zM7OIkD28VHAQmCWDWzq0yNKR7Ab8jw2HcZ3rGhx5u5D1jAR9qXSLKBjB3
  // 7fYFpSasfgqW4vLDmbOkkQafX7jh87kkdQIDAQAB
  // -----END RSA PUBLIC KEY-----`;

  SetSession(token) {
    localStorage.setItem(LocalStorageType.Session, token)

    return this.GetSession()
  }

  async GetSession() {
    let token = localStorage.getItem(LocalStorageType.Session)

    let verifyResult = KJUR.jws.JWS.verify(token, this.cert, ["RS256"]);

    if (verifyResult) {
      let result = await KJUR.jws.JWS.readSafeJSONString(b64utoutf8(token.split('.')[1]));

      return result;
    } else {
      throw 'Invalid Session'
    }
  }

  setAnnouncement(announcement: string) {
    return localStorage.setItem(LocalStorageType.Announcement, announcement);
  }

  getAnnouncement() {
    return localStorage.getItem(LocalStorageType.Announcement);
  }

  clearAnnouncement() {
    return localStorage.removeItem(LocalStorageType.Announcement);
  }

  // private user: Observable<firebase.User>;
  // private userDetails: firebase.User = null;

  // constructor(public _firebaseAuth: AngularFireAuth, public router: Router) {
  //   this.user = _firebaseAuth.authState;
  //   this.user.subscribe(
  //     (user) => {
  //       if (user) {
  //         this.userDetails = user;
  //       }
  //       else {
  //         this.userDetails = null;
  //       }
  //     }
  //   );

  // }

  // signupUser(email: string, password: string) {
  //   //your code for signing up the new user
  // }

  // signinUser(email: string, password: string) {
  //   //your code for checking credentials and getting tokens for for signing in user
  //   // return this._firebaseAuth.signInWithEmailAndPassword(email, password)

  //   //uncomment above firebase auth code and remove this temp code
  //   return new Promise(function(resolve, reject) {
  //     setTimeout(function() {
  //       resolve(true);
  //     }, 1000);
  //   });

  // }

  // logout() {
  //   this._firebaseAuth.signOut();
  //   this.router.navigate(['YOUR_LOGOUT_URL']);
  // }

  // isAuthenticated() {
  //   return true;
  // }
  
}
